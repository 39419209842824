import * as productsService from '../../services/products';
import * as actionTypes from './actionTypes';

const fetchData = (startAction, successAction, errorAction, serviceMethod, dataKey, serviceMethodId) => {
	return (dispatch) => {
		dispatch(startAction());
		return serviceMethod(serviceMethodId)
			.then(({ data }) => {
				dispatch(successAction({ [dataKey]: data ?? [] }));
				return data;
			})
			.catch((error) => {
				dispatch(errorAction(error));
			});
	};
};

export const getProductAddons = () => {
	return fetchData(
		startFetchingProductAddons,
		getProductAddonsSuccess,
		getProductAddonsError,
		productsService.getProductAddons,
		'productAddons'
	);
};

export const getProductAddonDetails = (id) => {
	return fetchData(
		startFetchingProductAddonDetails,
		getProductAddonDetailsSuccess,
		getProductAddonDetailsError,
		productsService.getProductAddonDetails,
		'productAddonDetails',
		id
	);
};

const startFetchingProductAddons = () => {
	return { type: actionTypes.START_FETCHING_PRODUCT_ADDONS };
};

const getProductAddonsSuccess = (payload) => {
	return { type: actionTypes.GET_PRODUCT_ADDONS_SUCCESS, payload };
};

const getProductAddonsError = (error) => {
	return { type: actionTypes.GET_PRODUCT_ADDONS_ERROR, payload: error };
};

const startFetchingProductAddonDetails = () => {
	return { type: actionTypes.START_FETCHING_PRODUCT_ADDON_DETAILS };
};

const getProductAddonDetailsSuccess = (payload) => {
	return { type: actionTypes.GET_PRODUCT_ADDON_DETAILS_SUCCESS, payload };
};

const getProductAddonDetailsError = (error) => {
	return { type: actionTypes.GET_PRODUCT_ADDON_DETAILS_ERROR, payload: error };
};

export const removeProductAddonDetails = () => {
	return { type: actionTypes.REMOVE_PRODUCT_ADDON_DETAILS };
};

export const onShowModalAddProductAddon = () => { return { type: actionTypes.SHOW_ADD_PRODUCT_ADDON_MODAL }; };
export const onHideModalAddProductAddon = () => { return { type: actionTypes.HIDE_ADD_PRODUCT_ADDON_MODAL }; };
