import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, getFormSyncErrors, reduxForm } from 'redux-form';
import { Button } from '@syneto/compass-react';
import RenewalsFieldsValidation from './data/validation/renewals-fields';
import Form from '../../../../components/forms/form';
import {
	addSettingsForEmailFrequencies,
	getRenewalsSettings
} from './data/actions';
import {
	pushDangerNotification,
	pushSuccessfulNotification
} from '../../../../data/notifications/actions';

const FORM_NAME = 'emailFrequencies';

let AdminSettingsRenewalsTab = (props) => {
	const { handleSubmit, submitting, invalid, internalWeeklyReportSettingsFieldErrors, emailFrequenciesSettingsFieldErrors, addonsExpirationEmailFrequenciesErrors, addSettingsForEmailFrequencies, getRenewalsSettings, pushSuccessfulNotification, pushDangerNotification, reset } = props;

	useEffect(() => {
		getRenewalsSettings().then(() => {
			reset(FORM_NAME);
		});
	}, []);

	function submit(formValues) {
		addSettingsForEmailFrequencies(formValues).then(() => {
			pushSuccessfulNotification('Renewals settings updated.');
		}).catch(() => {
			pushDangerNotification('Failed to save. Must be in the format \"Xd,Yw,Zm\", where X, Y and Z are integers.');
		});
	}

	return (
		<form>
			<fieldset className="mb-5">
				<h1 className="mb-1">Customer Maintenance Notifications</h1>
				<p className="text-secondary mb-4 text-value">Notify customers by email about maintenance plans going to
					expire.</p>
				<div className="mb-3">
					<label htmlFor="emailFrequenciesSettings">Email sending intervals (before expiration)</label>
					<Form.text name="emailFrequenciesSettings" />{!emailFrequenciesSettingsFieldErrors &&
						<span className="text-secondary form-feedback">
							Example: 3m, 1m, 2w, 1d(3 months, 1 month, 2 weeks and 1 day before expiration)
						</span>
					}
				</div>
			</fieldset>

			<fieldset className="mb-5">
				<h1 className="mb-1">Add Ons Customer Notifications</h1>
				<p className="text-secondary mb-4 text-value">Notify customers by email about add-on plans going to expire.</p>
				<div className="mb-3">
					<label htmlFor="addonsExpirationEmailFrequencies">Email sending intervals (before expiration)</label>
					<Form.text name="addonsExpirationEmailFrequencies" />{!addonsExpirationEmailFrequenciesErrors &&
						<span className="text-secondary form-feedback">
							Example: 3m, 1m, 2w, 1d(3 months, 1 month, 2 weeks and 1 day before expiration)
						</span>
					}
				</div>
			</fieldset>

			<fieldset>
				<h1 className="mb-1">Internal weekly report</h1>
				<p className="text-secondary text-value mb-4">Send a weekly email with expired and going to expire maintenance
					plans</p>
				<div className="row">
					<div className="col-6">
						<div className="mb-3">
							<label htmlFor="maintenancePlansExpiresIn">Maintenance plans expire in</label>
							<Field className="form-control text-value" name="maintenancePlansExpiresIn" component="select">
								<option value="6m">next 6 months</option>
								<option value="3m">next 3 months</option>
								<option value="2m">next 2 months</option>
								<option value="1m">next month</option>
							</Field>
						</div>
					</div>
					<div className="col-6">
						<div className="mb-3">
							<label htmlFor="maintenancePlansExpired">Expired maintenance plans</label>
							<Field className="form-control text-value" name="maintenancePlansExpired" component="select">
								<option value="3m">last 3 months</option>
								<option value="1m">last month</option>
							</Field>
						</div>
					</div>
				</div>
				<div className="mb-3">
					<label htmlFor="weeklyReportEmailList">Emails list</label>
					<Form.text name="internalWeeklyReportSettings" />{!internalWeeklyReportSettingsFieldErrors &&
						<span className="text-secondary form-feedback">A list of emails separated by a comma</span>}
				</div>
			</fieldset>

			<fieldset className="mt-5">
				<h1 className="mb-1">User interface</h1>
				<p className="text-secondary text-value mb-4">Control how the user interface renders expiring/expired
					maintenance plans</p>
				<div className="mb-3">
					<label htmlFor="expiringIntervalSettingForCustomer">Mark machine as 'expiring' interval</label>
					<Field className="form-control text-value" name="expiringIntervalSettingForCustomer" component="select">
						<option value="30-days">30 days</option>
						<option value="60-days">60 days</option>
						<option value="90-days">90 days</option>
						<option value="120-days">120 days</option>
					</Field>
				</div>
			</fieldset>

			<fieldset className="mt-5">
				<h1 className="mb-1">Maintenance Extension</h1>
				<p className="text-secondary text-value mb-4">Configure the maximum amount of time a machine can be
					supported</p>
				<div className="mb-3">
					<label htmlFor="maxMaintenanceExtension">Maximum total maintenance (initial period + renewals)</label>
					<Field className="form-control text-value" name="maxMaintenanceExtension" component="select">
						<option value="36">3 years</option>
						<option value="60">5 years</option>
						<option value="84">7 years</option>
					</Field>
				</div>
			</fieldset>

			<Button disabled={submitting || invalid} onClick={handleSubmit(submit)} type="submit">
				{!submitting ? 'Save' : 'Saving...'}
			</Button>
		</form>
	);
};

AdminSettingsRenewalsTab = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	validate: RenewalsFieldsValidation.validate
})(AdminSettingsRenewalsTab);

const mapStateToProps = (state) => {
	const selector = getFormSyncErrors('emailFrequencies');
	const emailFrequenciesSettingsFieldErrors = selector(state, 'emailFrequenciesSettings');
	const addonsExpirationEmailFrequenciesErrors = selector(state, 'addonsExpirationEmailFrequencies');
	const internalWeeklyReportSettingsFieldErrors = selector(state, 'internalWeeklyReportSettings');
	const { renewalSettings } = state.adminSettings;

	return {
	
		statusSetEmailFrequencies: renewalSettings.statusSetEmailFrequencies,
		initialValues: {
			addonsExpirationEmailFrequencies: renewalSettings && renewalSettings.addonsExpirationEmailFrequencies ? renewalSettings.addonsExpirationEmailFrequencies : null,
			emailFrequenciesSettings: renewalSettings && renewalSettings.emailFrequenciesSettings ? renewalSettings.emailFrequenciesSettings : null,
			internalWeeklyReportSettings: renewalSettings && renewalSettings.internalWeeklyReportSettings ? renewalSettings.internalWeeklyReportSettings : null,
			maintenancePlansExpiresIn: renewalSettings && renewalSettings.maintenancePlansExpiresIn ? renewalSettings.maintenancePlansExpiresIn : '3m',
			maintenancePlansExpired: renewalSettings && renewalSettings.maintenancePlansExpired ? renewalSettings.maintenancePlansExpired : '3m',
			expiringIntervalSettingForCustomer: renewalSettings && renewalSettings.expiringIntervalSettingForCustomer ? renewalSettings.expiringIntervalSettingForCustomer : '30-days',
			maxMaintenanceExtension: renewalSettings && renewalSettings.maxMaintenanceExtension ? renewalSettings.maxMaintenanceExtension : 36
		},
		internalWeeklyReportSettingsFieldErrors,
		emailFrequenciesSettingsFieldErrors,
		addonsExpirationEmailFrequenciesErrors
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getRenewalsSettings: () => { return dispatch(getRenewalsSettings()); },
		addSettingsForEmailFrequencies: (values) => { return dispatch(addSettingsForEmailFrequencies(values)); },
		pushSuccessfulNotification: (message) => { return dispatch(pushSuccessfulNotification(message)); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettingsRenewalsTab);
