import React, { useEffect } from 'react';
import { Field, change, touch } from 'redux-form';
import { Form, DatePicker } from '@syneto/compass-react';
import { connect } from 'react-redux';
import moment from 'moment';

const FormDatePicker = (props) => {
	return (
		<Field {...props} component={renderField} />
	);
};

const renderField = (props) => {
	const { label, icon, required, id, name, className, style, changeProp, touchOnBlur, autoComplete, initValue, onValueChange } = props;
	const { placeholder, inputRef, key, disabled } = props;
	const { meta: { touched, error, warning } } = props;
	const [selectedDate, setSelectedDate] = React.useState(initValue? moment(initValue, 'DD/MM/YYYY').isValid()? moment(initValue, 'DD/MM/YYYY').toDate() : null: null);
	useEffect(() => {
		if (initValue && moment(initValue).isValid() && moment(initValue).toDate().getTime() !== selectedDate?.getTime()) {
			setSelectedDate(moment(initValue).toDate());
		}
	}, [initValue]);

	const updateValue = (newValue) => {
		if (newValue) {
			const parsedDate = moment(newValue);
			setSelectedDate(parsedDate.toDate()); // Update local state
			changeProp(parsedDate.format('DD/MM/YYYY')); // Update Redux state with formatted date
			if (onValueChange) {
				onValueChange(parsedDate.format('YYYY-MM-DD')); // Pass ISO format to parent
			}
		} else {
			setSelectedDate(null); // Clear local state
			changeProp(null); // Clear Redux state
			if (onValueChange) {
				onValueChange(null); // Pass null to parent
			}
		}
	};

	return (
		<Form.Group className="mb-3" controlId={id}>
			{label && (
				<Form.Label>
					{label}{required ? '*' : ''} {icon || null}
				</Form.Label>
			)}

			<Form.Control
				type="hidden"
				isInvalid={touched && error}
				name={name}
			/>

			<DatePicker
				key={key || id}
				ref={inputRef}
				placeholderText={placeholder || 'Select a date'}
				style={style}
				className={`form-control ${className} ${touched && error ? 'is-invalid' : ''}`}
				isInvalid={touched && error}
				selected={selectedDate}
				onChange={(newValue) => { updateValue(newValue); }}
				autoComplete={autoComplete}
				onBlur={touchOnBlur}
				disabled={disabled}
			/>

			{touched && warning && <span>{warning}</span>}

			<Form.Control.Feedback type="invalid">
				{error}
			</Form.Control.Feedback>
		</Form.Group>
	);
};

const mapDispatchToProps = (dispatch, ownProps) => {
	if (!ownProps.formName || !ownProps.name) {
		return {};
	}

	return {
		changeProp: (value) => { dispatch(change(ownProps.formName, ownProps.name, value)); },
		touchOnBlur: () => { dispatch(touch(ownProps.formName, ownProps.name)); }
	};
};

export default connect(null, mapDispatchToProps)(FormDatePicker);
