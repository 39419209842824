import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs } from '@syneto/compass-react';
import ResultItemCard from '../../ResultItemCard';
import PendingCard from '../CustomersMaintenance/PendingCard';

const ProductsTableSearchResults = (props) => {
	const { query, maintenanceServiceDetails, selectedProduct, products } = props;
	const [assemblyTests, setAssemblyTests] = useState();

	useEffect(() => {
		let product = products.find((product) => { return product.serialNumber === selectedProduct.serialNumber; });
		if (product && product.assemblyTests && product.assemblyTests.length) {
			setAssemblyTests(product.assemblyTests[0]);
		}
	}, [selectedProduct]);

	const renderProductAndMaintenanceService = (maintenanceServiceDetails) => {
		if (!maintenanceServiceDetails.product) {
			return <p className="ms-3 font-weight-bold mt-1" style={{ fontSize: '14px' }}>There are no products
				associated with this account.</p>;
		}

		return (
			<div className="row" key={maintenanceServiceDetails.id}>
				<div className="col-5">
					<ResultItemCard product={selectedProduct} query={query} />
				</div>
				<div className="col-7 mt-2">
					<PendingCard
						selectedProduct={selectedProduct}
						product={maintenanceServiceDetails.product}
						assemblyTests={assemblyTests}
					/>
				</div>
			</div>
		);
	};

	return (
		<div>
			{!maintenanceServiceDetails ? null :
				<div className="mt-4">
					<Tabs>
						<Tabs.Tab eventKey="products" title="Products and maintenance plans">
							{renderProductAndMaintenanceService(maintenanceServiceDetails)}
						</Tabs.Tab>
					</Tabs>
				</div>
			}
		</div>
	);
};

const mapStateToProps = ({ productMaintenanceService, products }) => {
	return {
		maintenanceServiceDetails: productMaintenanceService.maintenanceService,
		products: products.products
	};
};

export default connect(mapStateToProps)(ProductsTableSearchResults);
