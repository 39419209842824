import axios from 'axios';

axios.defaults.baseURL = window.REACT_APP_API_URL;

const backEndAxios = axios.create({
	baseURL: window.REACT_APP_BACKEND_PROXY_URL
}); 

const restAxios = axios.create({
	baseURL: window.REACT_APP_REST_URL
});

const setAxiosAuthorizationHeader = (token) => {
	axios.defaults.headers.common['Authorization'] = token;
	backEndAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	restAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const API_DEFAULT_VERSION = 'v1';
const API_V2 = 'v2';

export {
	axios,
	backEndAxios,
	restAxios,
	API_DEFAULT_VERSION,
	API_V2,
	setAxiosAuthorizationHeader
};
