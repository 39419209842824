import React from 'react';
import GenerateDownloadLinkForOSManual from '../../../../helpers/GenerateDownloadLinkForOSManual';
import OSVersionParserService from '../../../../helpers/OSVersionParser';
import AdminProductMaintenancePlansTable from './AdminProductMaintenancePlansTable';

const ProductDetails = (props) => {
	const { product } = props;

	console.log(product);

	return (
		<div className="row">
			<div className="col-6">
				<ul className="list-unstyled">
					<li className="pt-2 pb-1"><h5>Appliance</h5></li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Serial number: </label><span
						className="text-value">{product.serialNumber ? product.serialNumber : 'N/A'}</span></li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Model: </label><span
						className="text-value">{product?.modelName ?? 'N/A'} {product.activatedModel ? ` (${product.activatedModel})` : null}</span>
					</li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">SynetoOS Version: </label><span
						className="text-value">{product.osVersion ? GenerateDownloadLinkForOSManual.generateDownloadLink(OSVersionParserService.parseOSVersion(product.osVersion)) : 'N/A'}</span>
					</li>
				</ul>
			</div>
			<div className="col-6">
				<ul className="list-unstyled">
					<li className="pt-2 pb-1"><h5>Contact</h5></li>
					<li className="pt-2 pb-1 top-border"><label className="pf-3">Customer: </label><span
						className="text-value">{product?.customer ? product.customer : 'N/A'}</span>
					</li>
				</ul>
			</div>

			{/* {
				product.activationDate && (
					<div className="col">
						{product.maintenanceService.startDate ? <h5>Support</h5> : null}
						<AdminProductMaintenancePlansTable product={product} />
					</div>
				)
			} */}

		</div>
	);
};

export default ProductDetails;
