import React from 'react';
import { Badge } from '@syneto/compass-react/lib';

const Addons = (props) => {
	let { addons } = props;
	
	const renderAddonStatus = (status) => {
		if (status.toLowerCase() === 'active' || status.toLowerCase() === 'trial') {
			return <Badge appearance="success">Active</Badge>;
		}
		if (status.toLowerCase() === 'expired') {
			return <Badge appearance="danger">Expired</Badge>;
		} else {
			return <Badge appearance="info">{status}</Badge>;
		}
	};

	return (
		addons?.length ?
			<table className="table table-hover">
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">Tier</th>
						<th scope="col">Status</th>
						<th scope="col">Start Date</th>
						<th scope="col">End Date</th>
					</tr>
				</thead>
				<tbody>
					{addons.map((addon, index) => {
						return (
							<tr key={index}>
								<td>{addon?.addon?.name}</td>
								<td>{addon?.tier?.name || 'N/A'}</td>
								<td>{renderAddonStatus(addon?.status)}</td>
								<td>{(addon?.startDate ? moment(addon.startDate).format('DD/MM/YYYY') : 'N/A')}</td>
								<td>{(addon?.endDate ? moment(addon.endDate).format('DD/MM/YYYY') : 'N/A')}</td>
							</tr>
						);
					})}
				</tbody>
			</table> :
			<div>No add-on available</div>

	);
};

export default Addons;
