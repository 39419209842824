import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@syneto/compass-react';
import UserRoles from '../../../constants/user-roles';

const NavLinksConst = (props) => {
	const { menuSections, userRole } = props;
	const { headerTextIdentifier, headerMenuOptions } = menuSections;

	const renderHeaderMenu = (headerTextIdentifier, headerMenuOptions) => {
		return (
			<div className='d-flex align-items-center'>
				<span className='active-section-identifier'>{ headerTextIdentifier }</span>

				{
					headerMenuOptions?.map((headerMenuOption, key) => {
						return (
							<span key={ key }>
								<div className="btn-group">
									<Button
										role="tertiary"
										onClick={ () => { return headerMenuOption.fnToExecute(); } }
										type="submit"
									>
										{ headerMenuOption.menuTitle }
									</Button>
								</div>
							</span>
						);
					})
				}
			</div>
		);
	};

	if ( [
		UserRoles.CLIENT,
		UserRoles.OPERATIONS,
		UserRoles.SUPPORT_ENGINEER,
		UserRoles.MARKETING,
		UserRoles.ADMINISTRATOR
	].includes(userRole) ) {
		return renderHeaderMenu(headerTextIdentifier, headerMenuOptions);
	}

	return null;
};

const mapStateToProps = ({ menuSections }) => { return { menuSections: menuSections.section }; };

export const NavLinks = connect(mapStateToProps)(NavLinksConst);
