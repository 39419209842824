import React from 'react';
import { Route, Routes } from 'react-router';

import UserRoles from '../constants/user-roles';

import Layout from '../components/Layout';
import PrivateRoute from '../components/PrivateRoute';

import NotFound from '../modules/NotFound';
import Authentication from '../modules/Authentication';
import PasswordRecovery from '../modules/Password-Recovery';
import PasswordReset from '../modules/Password-Reset';
import NPS from '../modules/NpsSurvey';

import Support from '../modules/Support';
import SupportCustomers from '../modules/Support/components/SupportCustomers';
import SupportProducts from '../modules/Support/components/SupportProducts';
import SupportSerenityEvents from '../modules/Support/components/SupportSerenityEvents';
import SupportVMActivationCodes from '../modules/Support/components/SupportVMActivationCodes';

import MaintenanceServicesMaster from '../modules/Operations';
import CompaniesTable from '../modules/Operations/components/OperationsCompanies/operationsCompaniesTable';
import ProductsPage from '../modules/Operations/components/Products/ProductsPage';

import Administrator from '../modules/Administrator';
import AdminInternalUsers from '../modules/Administrator/components/AdminInternalUsers';
import AdminSettingsForm from '../modules/Administrator/components/AdminSettings/adminSettings';
import AdminCustomers from '../modules/Administrator/components/AdminCustomers';
import AdminManuals from '../modules/Administrator/components/AdminManuals';
import AdminProductModels from '../modules/Administrator/components/AdminProductModels';
import AdminProducts from '../modules/Administrator/components/AdminProducts';
import AdminMaintenanceTypes from '../modules/Administrator/components/AdminMaintenanceTypes';

import Marketing from '../modules/Marketing';
import MarketingEndUserAccounts from '../modules/Marketing/components/MarketingEndUserAccounts';
import MarketingReports from '../modules/Marketing/components/MarketingReports';
import ProductPage from '../modules/Operations/components/Products/ProductPage';
import ProductAddons from '../modules/Operations/components/ProductAddons';

const Router = () => {
	return (
		<Routes>
			<Route path='/' element={<Layout />}>
				<Route path='sign-in' element={<Authentication />} />
				<Route path='password-recovery' element={<PasswordRecovery/>} />
				<Route path='password-reset' element={<PasswordReset/>}>
					<Route path=':passwordResetToken'/>
				</Route>

				<Route path='nps/:hash/:likelihoodToRecommend/:criteriaList/:language' element={<NPS/>} />

				<Route element={<PrivateRoute/>}>
					<Route path='support' element={<Support/>}>
						<Route path='customers' element={<SupportCustomers />} />
						<Route path='products' element={<SupportProducts />} />
						<Route path='serenity-events' element={<SupportSerenityEvents />} />
						<Route path='vm-activation-codes' element={<SupportVMActivationCodes />} />
					</Route>			

					<Route path='maintenance-service' element={<MaintenanceServicesMaster/>}>
						<Route path='maintenance-plans' element={<ProductsPage/>}/>
						<Route path='new-product' element={<ProductPage/>} />
						<Route path='product/:productId' element={<ProductPage isEditing="true"/>} />
						<Route path='hyper-models' element={<AdminProductModels/>} />
						<Route path='product-addons' element={<ProductAddons/>} />
						<Route path='companies' element={<CompaniesTable/>} />
						<Route path='maintenance-types'element={<AdminMaintenanceTypes/>} />
					</Route>
				
					<Route path='user-management' element={<Administrator/>}>
						<Route path={UserRoles.CLIENT} element={<CompaniesTable/>}/>
						<Route path={UserRoles.SUPPORT_ENGINEER} element={<AdminInternalUsers role={UserRoles.SUPPORT_ENGINEER} />} />
						<Route path={UserRoles.OPERATIONS} element={<AdminInternalUsers role={UserRoles.OPERATIONS} />} />
						<Route path={UserRoles.ADMINISTRATOR} element={<AdminInternalUsers role={UserRoles.ADMINISTRATOR} />} />
						<Route path={UserRoles.MARKETING} element={<AdminInternalUsers role={UserRoles.MARKETING} />} />
						<Route path='customers' element={<AdminCustomers />} />
						<Route path='manuals' element={<AdminManuals />} />
						<Route path='products' element={<AdminProducts />} />
						<Route path='settings' element={<AdminSettingsForm />} />
						<Route path='maintenance-types' element={<AdminMaintenanceTypes/>} />
						<Route path='product-models' element={<AdminProductModels/>} />
					</Route>

					<Route path='marketing' element={<Marketing />} >
						<Route path='end-user-accounts' element={<MarketingEndUserAccounts />} />
						<Route path='reports' element={<MarketingReports />} />
					</Route>
				</Route>

				<Route path='*' element={<NotFound/>} />
			</Route>
		</Routes>
	);
};

export default Router;
