import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { Form } from '@syneto/compass-react';
import ReactSelect from 'react-select';
import { flatAutoCompleteOptions } from '../../helpers/normalize-data';

const Select = (props) => { return <Field { ...props } component={ renderField }/>; };

const renderField = (props) => {
	const { options, disabled, input, label, emptyOption, defaultValue, required, meta, onValueChange, isTableFilter } = props;
	const { touched, error, warning } = meta;
	const { value, onChange } = input;
	const getOptionByValue = (value) => { return flatAutoCompleteOptions(options).filter((option) => { return option.value === value; }); };
	const [selectedOption, setSelectedOption] = useState(getOptionByValue(value) ?? '');
	const groupStyle = isTableFilter ? 'd-flex gap-2 align-items-center' : 'mb-3';
	const labelStyle = isTableFilter ? 'mb-0' : '';
	
	useEffect(() => {
		setSelectedOption(getOptionByValue(value));
	}, [value]);

	useEffect(() => {
		// temp fix for serenity new events select
		if (options && options.length > 0 && !defaultValue && options[0].value !== '') {
			setSelectedOption(getOptionByValue(value));
		}
	}, [options]);

	useEffect(() => {
		if (defaultValue) {
			setSelectedOption(getOptionByValue(defaultValue));
		}
	}, [defaultValue]);

	const handleChange = (event) => {
		// onChange event to set new value to Field component
		onChange(event.value);
		setSelectedOption(event);

		if (onValueChange) {
			// Custom onChange event to pass changed value to parent component
			onValueChange(event.value);
		}
	};

	return (
		<Form.Group className={groupStyle}>
			{ label ? <Form.Label className={labelStyle}>{ label }{ required ? '*' : null }</Form.Label> : null }

			<ReactSelect
				unstyled
				className={`form-control ${ touched && error ? 'is-invalid' : ''}`}
				classNamePrefix='form-control'
				options={options}
				isDisabled={disabled}
				onChange={handleChange}
				placeholder={emptyOption}
				value={selectedOption}
			/>

			{ touched && warning && <span>{ warning }</span> }

			<Form.Control.Feedback type="invalid">
				{ error }
			</Form.Control.Feedback>
		</Form.Group>
	);
};

export default Select;
