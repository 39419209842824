import React, { Component } from 'react';
import Input from './input';
import Select from './select';
import { Checkbox } from './checkbox';
import { Textarea } from './textarea';
import { Radio } from './radio';
import { Switch } from './switch';
import Mfa from './mfa';
import FormDatePicker from './datepicker';

class Form {
}

Form.hidden = class extends Component {
	render() {
		return <Input type="hidden" {...this.props} />;
	}
};

Form.text = class extends Component {
	render() {
		return <Input type="text" {...this.props} />;
	}
};

Form.number = class extends Component {
	render() {
		return <Input type="number" {...this.props} />;
	}
};

Form.select = class extends Component {
	render() {
		return <Select {...this.props} />;
	}
};

Form.textarea = class extends Component {
	render() {
		return Textarea(this.props);
	}
};

Form.email = class extends Component {
	render() {
		return <Input type="email" {...this.props} />;
	}
};

Form.password = class extends Component {
	render() {
		return <Input type="password" {...this.props} />;
	}
};

Form.checkbox = class extends Component {
	render() {
		return Checkbox(this.props);
	}
};

Form.radio = class extends Component {
	render() {
		return Radio(this.props);
	}
};

Form.switch = class extends Component {
	render() {
		return <Switch {...this.props} />;
	}
};

Form.mfa = class extends Component {
	render() {
		return <Mfa {...this.props} />;
	}
};

Form.datepicker = class extends Component {
	render() {
		return <FormDatePicker {...this.props}/>;
	}
};

export default Form;

