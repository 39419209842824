import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Badge, Button, Icon, Tooltip } from '@syneto/compass-react';
import { connect } from 'react-redux';
import { getProductAddons } from '../../../../data/productAddons/actions';
import Form from '../../../../components/forms/form';

const ProductAddon = (props) => {
	const { productAddons: availableProductAddons, getProductAddons, changeProp, removeAddon, changeAddon, addon, index } = props;
	const [isExpired, setIsExpired] = useState(false);

	useEffect(() => {
		getProductAddons();
	}, []);

	useEffect(() => {
		if (addon.endDate) {
			const endDate = new Date(addon.endDate);
			const now = new Date();
			setIsExpired(endDate < now);
		}
	}, [addon]);

	return (
		<Row>
			<Col xs={3}>
				<Form.select
					name={`productAddons[${index}].addonId`}
					options={(availableProductAddons.map((addon) => { return { value: addon.id, label: addon.name }; }))}
					emptyOption="Please select"
					onValueChange={(value) => { changeAddon({ ...addon, addonId: value }); }}
				/>
			</Col>
			<Col xs={2}>
				<Form.datepicker
					// calendarClassName="activation-details__date-picker"
					id={`productAddons[${index}].startDate`}
					name={`productAddons[${index}].startDate`}
					placeholderText="Not set"
					placement="left"
					initValue={(addon.startDate && addon.id && addon.startOnActivation) || (addon.startDate && !addon.startOnActivation) ? moment(addon.startDate).format('DD/MM/YYYY') : ''}
					autoComplete="off"
					onValueChange={(value) => {
						changeAddon({ ...addon, startDate: (value && !addon.startOnActivation ? moment(value).tz('UTC', true).startOf('day').toISOString() : '') });
					}}
					changeProp={changeProp}
					key={addon.startOnActivation ? 'empty' : `productAddons[${index}].startDate`}
					disabled={addon.startOnActivation}
				/>
			</Col>
			<Col xs={1}>
				<Form.number name={`productAddons[${index}].duration`} onChange={(e) => { changeAddon({ ...addon, duration: e.currentTarget.value }); }} required />
			</Col>
			<Col xs={2}>
				<Form.hidden name={`productAddons[${index}].addonType`} value={addon.addonType} />
				<Form.select
					name={`productAddons[${index}].durationType`}
					options={[
						{ value: 'days', label: 'Days' },
						{ value: 'months', label: 'Months' },
						{ value: 'years', label: 'Years' }
					]}
					defaultValue={'months'}
					onValueChange={(value) => { changeAddon({ ...addon, durationType: value }); }}
				/>
			</Col>
			<Col xs={2}>
				<Form.select
					disabled={!addon.addonId}
					name={`productAddons[${index}].tierId`}
					options={(availableProductAddons?.
						find((a) => { return a.id === addon.addonId; })?.
						addonTiers?.map((tier) => { return { value: tier.id, label: tier.name }; })
						?? []
					)}
					onValueChange={(value) => { changeAddon({ ...addon, tierId: value }); }}
				/>
			</Col>
			<Col>
				<Form.checkbox
					name={`productAddons[${index}].startOnActivation`}
					onValueChange={(value) => { changeAddon({ ...addon, startOnActivation: value }); }}
				/>
			</Col>
			<Col xs={1} className="position-relative">
				{isExpired &&
						<Tooltip text="Expired. Please renew this add-on" placement="top" trigger="hover">
							<Badge className="position-absolute top-0 start-0" style={{ transform: 'translateX(-50%)' }}>
								<Icon name="fa fa-exclamation" className="text-warning" />
							</Badge>
						</Tooltip>
				}
				<Button role="tertiary" className="py-0" onClick={() => { removeAddon(addon.uuid); }}><Icon name="fa fa-trash" className="text-muted" /></Button>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		productAddons: state.productAddons.data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProductAddons: () => { return dispatch(getProductAddons()); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductAddon);

ProductAddon.propTypes = {
	removeAddon: PropTypes.func.isRequired,
	changeAddon: PropTypes.func.isRequired,
	changeProp: PropTypes.func.isRequired,
};

