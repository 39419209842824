import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import * as authActionTypes from './authenticate/actionTypes';
import AuthenticationReducer from './authenticate/reducer';
import Notifications from './notifications/reducer/notifications';
import SidebarReducer from './reducers/sidebar';
import MenuSectionsReducer from './reducers/navigation';
import ExportToCSV from './export-csv/reducer/export-to-csv';
import SelectedUser from './selected-user/reducer';
import UserProfileReducer from './profile/reducer';
import UsersReducer from './users/reducer';
import CustomerReducer from './customers/reducer';
import SelectedCustomerReducer from './selected-customer/reducer';
import CompaniesReducer from './companies/reducer';
import SerenityEvents from './serenity-events/reducer';
import ProductsReducer from './product/reducer';
import ProductAddonsReducer from './productAddons/reducer';
import MarketingReport from './marketingReport/reducer';
import MaintenancePlansOptions from './maintenanceType/reducer';
import ProductModels from './productModels/reducer';
import SettingsReducer from './settings/reducer';
import ProductMaintenanceService from './productMaintenanceService/reducer';

//The reducers below need to be moved from modules folder to data folder
import UserPasswordRecoveryReducer from '../modules/Password-Recovery/data/reducers';
import UserPasswordResetReducer from '../modules/Password-Reset/data/reducers';
import UserProducts from '../modules/Support/data/reducers/user-products';
import ProductNotesReducer from '../modules/Support/data/reducers/product-notes';
import ProductUpdateEventsReducer from './update-events/reducer/update-events';
import Settings from '../modules/Administrator/components/AdminSettings/data/reducer';
import ActivationKeys from '../modules/Support/data/reducers/vm-activation-keys';
import SupportTunnels from '../modules/Support/data/reducers/support-tunnels';
import AccessToken from '../modules/Support/data/reducers/acessToken';
import NpsSurvey from '../modules/NpsSurvey/data/reducers';

const appReducer = combineReducers({
	form: reduxFormReducer,
	authentication: AuthenticationReducer,
	passwordRecovery: UserPasswordRecoveryReducer,
	passwordReset: UserPasswordResetReducer,
	user: UserProfileReducer,
	userProducts: UserProducts,
	users: UsersReducer,
	customers: CustomerReducer,
	companies: CompaniesReducer,
	marketingReport: MarketingReport,
	activationKeys: ActivationKeys,
	products: ProductsReducer,
	productAddons: ProductAddonsReducer,
	productNotes: ProductNotesReducer,
	productUpdateEvents: ProductUpdateEventsReducer,
	productMaintenanceService: ProductMaintenanceService,
	maintenancePlansOptions: MaintenancePlansOptions,
	exportToCSV: ExportToCSV,
	adminSettings: Settings,
	menuSections: MenuSectionsReducer,
	productModels: ProductModels,
	serenityEvents: SerenityEvents,
	supportTunnels: SupportTunnels,
	accessToken: AccessToken,
	npsSurvey: NpsSurvey,
	notifications: Notifications,
	sidebar: SidebarReducer,
	selectedUser: SelectedUser,
	selectedCustomer: SelectedCustomerReducer,
	settings: SettingsReducer
});

//RESET ENTIRE GLOBAL STATE TREE AFTER USER LOGS OUT
export const rootReducer = (state, action) => {
	if (action.type === authActionTypes.UNAUTHENTICATE) {
		state = undefined;
	}

	return appReducer(state, action);
};
