import * as actionTypes from './actionTypes';

export const INITIAL_STATE = {
	status: null,
	loading: false,
	error: null,
	data: [],
	selectedAddon: null,
	pagination: {},
	showAddProductAddonModal: false
};

export default (state = INITIAL_STATE, action) => {
	let error;
	switch (action.type) {
		case actionTypes.START_FETCHING_PRODUCT_ADDONS:
			return {
				...INITIAL_STATE,
				status: 'loading',
				loading: true
			};
		case actionTypes.GET_PRODUCT_ADDONS_SUCCESS: {
			return {
				...state,
				status: 'success',
				loading: false,
				data: action.payload.productAddons,
				pagination: action.payload.pagination
			};
		}
		case actionTypes.GET_PRODUCT_ADDONS_ERROR: {
			return {
				...state,
				status: 'failed',
				error: error,
				loading: false
			};
		}
		case actionTypes.GET_PRODUCT_ADDON_DETAILS_SUCCESS: {
			return {
				...state,
				selectedAddon: action.payload.productAddonDetails
			};
		}
		case actionTypes.GET_PRODUCT_ADDON_DETAILS_ERROR: {
			return {
				...state,
				selectedAddon: null,
			};
		}
		case actionTypes.REMOVE_PRODUCT_ADDON_DETAILS: {
			return {
				...state,
				selectedAddon: null,
			};
		}
		case actionTypes.SHOW_ADD_PRODUCT_ADDON_MODAL:
			return {
				...state,
				showAddProductAddonModal: true
			};
		case actionTypes.HIDE_ADD_PRODUCT_ADDON_MODAL:
			return {
				...state,
				showAddProductAddonModal: false,
				activationKey: null
			};
		default:
			return state;
	}
};
