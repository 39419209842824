import React, { useCallback, useEffect, useState } from 'react';
import { Row } from '@syneto/compass-react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { initialize, reduxForm, submit } from 'redux-form';
import ProductValidationService from '../../validation/ProductValidationService';
import Utils from '../../../../helpers/Utils';
import { createProduct, updateProduct } from '../../../../services/products';
import Currency from '../../../../helpers/money';
import ProductForm from './ProductForm';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';
import { getProductById, getProducts, resetProduct } from '../../../../data/product/actions';
import { GET_PRODUCTS_DEFAULT_QUERY_PARAMS } from '../../../../constants/queryParams';
import { getJob } from '../../../../services/jobs';

const FORM_NAME = 'product';

let ProductPage = (props) => {
	const { selectedProduct, productError, getProductById, resetProduct, initializeForm, change, handleSubmit, initialValues, getProducts, pushDangerNotification, pushSuccessfulNotification } = props;
	const navigate = useNavigate();
	const { productId } = useParams();
	const [isSendingRequest, setIsSendingRequest] = useState(false);
	const productsQueryParams = window.localStorage.getItem('productsParams') ? JSON.parse(window.localStorage.getItem('productsParams')) : GET_PRODUCTS_DEFAULT_QUERY_PARAMS;

	useEffect(() => {
		if (selectedProduct) {
			initializeForm(FORM_NAME, selectedProduct);
		}
	}, [selectedProduct]);

	useEffect(() => {
		if (productId) {
			getProductById(productId);
		}

		return () => {
			resetProduct();
			initializeForm(FORM_NAME, initialValues);
		};
	}, [productId]);

	const submitCreateProduct = useCallback((product) => {
		const currency = Currency.EUR();
		let data = { ...product };
		data.saleValue = !_.isEmpty(data.saleValue) ? parseFloat((Utils.normalizeSaleValue(data.saleValue) * currency.getCentFactor()).toFixed(2)) : null;
		data.maintenanceServices[0].saleValue = !_.isEmpty(data.maintenanceServices[0].saleValue) ? parseFloat((Utils.normalizeSaleValue(data.maintenanceServices[0].saleValue) * currency.getCentFactor()).toFixed(2)) : null;
		const waitForJob = (jobId) => {
			getJob(jobId)
				.then((jobResponse) => {
					if (jobResponse.data.status.toLowerCase() === 'succeeded') {
						setIsSendingRequest(false);
						pushSuccessfulNotification('Product created successfully');
						getProducts(productsQueryParams);
						navigate('/maintenance-service/maintenance-plans');
					} else if (jobResponse.data.status.toLowerCase() === 'failed') {
						setIsSendingRequest(false);
						pushDangerNotification(`An error occurred: ${jobResponse.data.statusMessage}`);
					} else {
						setTimeout(waitForJob, 1000, jobId);
					}
				})
				.catch((error) => {
					setIsSendingRequest(false);
					pushDangerNotification(`An error occurred: ${error}`);
				});
		};

		setIsSendingRequest(true);
		createProduct(data)
			.then((response) => {
				setTimeout(() => {
					waitForJob(response.data.jobId);
				}, 2000);
			})
			.catch((error) => {
				pushDangerNotification(error.response?.data?.message ?? error.response?.data?.detail ?? error.message ?? 'An error occurred');
				setIsSendingRequest(false);
			});
	}, []);

	const submitUpdateProduct = useCallback((product) => {
		const currency = Currency.EUR();
		let data = { ...product };
		data.saleValue = !_.isEmpty(data.saleValue) ? parseFloat((Utils.normalizeSaleValue(data.saleValue) * currency.getCentFactor()).toFixed(2)) : null;
		data.maintenanceServices = data.maintenanceServices.map((maintenanceService) => {
			maintenanceService.saleValue = !_.isEmpty(maintenanceService.saleValue) ? parseFloat((Utils.normalizeSaleValue(maintenanceService.saleValue) * currency.getCentFactor()).toFixed(2)) : null;
			return maintenanceService;
		});
		const waitForJob = (jobId) => {
			getJob(jobId)
				.then((jobResponse) => {
					if (jobResponse.data.status.toLowerCase() === 'succeeded') {
						setIsSendingRequest(false);
						pushSuccessfulNotification('Product updated successfully');
						getProducts(productsQueryParams);
						navigate('/maintenance-service/maintenance-plans');
					} else if (jobResponse.data.status.toLowerCase() === 'failed') {
						setIsSendingRequest(false);
						pushDangerNotification('An error occurred');
					} else {
						setTimeout(waitForJob, 1000, jobId);
					}
				})
				.catch((error) => {
					pushDangerNotification(`An error occurred: ${error}`);
				});
		};

		setIsSendingRequest(true);
		updateProduct(data)
			.then((response) => {
				waitForJob(response.data.jobId);
			})
			.catch((error) => {
				pushDangerNotification(error.response?.data?.detail ? error.response?.data?.detail : 'An error occurred');
				setIsSendingRequest(false);
			});
	}, []);

	return (
		<div className="p-4 bg-white">
			<Row className="pb-4 border-bottom">
				<div className="d-inline-flex">
					<span className="me-3" role="button" onClick={() => { getProducts(productsQueryParams); navigate('/maintenance-service/maintenance-plans'); }}><i className="fa fa-arrow-left" aria-hidden="true" style={{ fontSize: '24px' }}></i></span>
					<span style={{ lineHeight: '26px' }}>Product details</span>
				</div>
			</Row>
			{!!productError && <div className="alert alert-danger mt-4">Could not load product details.</div>}
			{(!productId || selectedProduct) && <ProductForm id="product" formName={FORM_NAME} initialValues={initialValues} changeFormFieldInState={change} handleSubmit={handleSubmit} submit={selectedProduct ? submitUpdateProduct : submitCreateProduct} isEditing={!!selectedProduct} isSendingRequest={isSendingRequest} product={selectedProduct} />}
		</div>);
};

const mapStateToProps = (state) => {
	const { products: { activationKey, selectedProduct, error }, maintenancePlansOptions: { maintenancePlansOptions } } = state;
	let firstPlan = (maintenancePlansOptions && maintenancePlansOptions.currentlySelling[0]) || '';

	return {
		productError: error,
		selectedProduct: selectedProduct,
		initialValues: {
			id: selectedProduct?.id ?? '',
			modelId: selectedProduct?.modelId ?? '',
			serialNumber: selectedProduct?.serialNumber ?? null,
			activationDate: selectedProduct?.activationDate ?? null,
			activationCode: selectedProduct?.activationCode ?? activationKey,
			saleValue: selectedProduct?.saleValue ?? '',
			userId: selectedProduct?.userId ?? '',
			productAddons: selectedProduct?.productAddons ?? [],
			maintenanceServices: selectedProduct?.maintenanceServices ?? [{
				maintenancePlanId: (firstPlan?.maintenancePlanId ?? ''),
				hasSerenity: (firstPlan ? firstPlan.serenity === 'included' : false),
				duration: 36,
				quoteNumber: '',
				saleValue: '',
				isRenewal: false,
			}],
		},
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		initializeForm: (formName, selectedProduct) => { return dispatch(initialize(formName, selectedProduct)); },
		getProductById: (productId) => { return dispatch(getProductById(productId)); },
		resetProduct: () => { return dispatch(resetProduct()); },
		getProducts: (queryParams) => { return dispatch(getProducts(queryParams)); },
		pushSuccessfulNotification: (message) => { return dispatch(pushSuccessfulNotification(message)); },
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); }
	};
};

ProductPage = reduxForm({
	form: FORM_NAME,
	validate: ProductValidationService.validate,
	warn: ProductValidationService.warn,
	onSubmit: submit
})(ProductPage);

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);

ProductPage.propTypes = {
	
};
