import React, { useState, useEffect } from 'react';
import { ReactReduxContext, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Badge } from '@syneto/compass-react';
import PulseGraph from '../../../../../components/graphs/pulse-graph';
import MaintenanceDetails from '../../MaintenanceDetails';
import SupportCustomersSupportNotes from '../../supportCustomersSupportNotes/supportCustomersSupportNotes';
import NotesIcon from './NoteIcon';
import SupportSerenityAllEventsTable from '../../SupportSerenityEvents/SupportSerenityAllEvents/supportSerenityAllEventsTable';
import CurrentlyActiveMaintenanceService from '../../../../../helpers/CurrentlyActiveMaintenanceService';
import RenderOnlyActiveTab from '../../../../../components/RenderOnlyActiveTab';
import { getNotesForProduct } from '../../../data/actions/actions';
import { getProductAddonsByProductId } from '../../../../../services/products';
import Addons from '../../Addons';

const ActiveCard = (props) => {
	const { userId, userData, product, productNotes, maintenanceServices, getNotesForProduct, assemblyTests } = props;
	const [tabsActiveKey, setTabsActiveKey] = useState('maintenance');
	const [addons, setAddons] = useState();
	let m = maintenanceServices.length && maintenanceServices.find((m) => { return m.product_id === product.id; });
	const maintenanceService = product.maintenanceService;
	const currentlyActiveMaintenanceService = maintenanceService ? CurrentlyActiveMaintenanceService(maintenanceService) : null;
	const internalNotes = currentlyActiveMaintenanceService?.plan?.internalNotes ?? currentlyActiveMaintenanceService?.maintenance_plan?.internal_notes;

	useEffect(() => {
		getNotesForProduct(product.id);
		getProductAddons(product.id);
	}, [product]);

	const getProductAddons = (productId) => {
		getProductAddonsByProductId(productId)
			.then((response) => {
				setAddons(response.data);
			});
	};

	return (
		<Tabs
			activeKey={tabsActiveKey}
			onSelect={(tabsActiveKey) => { return setTabsActiveKey(tabsActiveKey); }}
		>
			<Tabs.Tab eventKey="maintenance" title="Maintenance">
				<RenderOnlyActiveTab tabKey="maintenance" tabsActiveKey={tabsActiveKey}>
					<MaintenanceDetails
						userData={userData}
						product={product}
						maintenanceService={m}
						maintenanceServiceDetails={maintenanceService}
						currentlyActiveMaintenanceService={currentlyActiveMaintenanceService}
						maintenancePlanInternalNotes={internalNotes}
						assemblyTests={assemblyTests}
					/>
				</RenderOnlyActiveTab>
			</Tabs.Tab>

			<Tabs.Tab eventKey="addons" title="Add-ons">
				<RenderOnlyActiveTab tabKey="addons" tabsActiveKey={tabsActiveKey}>
					<Addons
						addons={addons}
					></Addons>
				</RenderOnlyActiveTab>
			</Tabs.Tab>

			<Tabs.Tab eventKey="pulse" title="Pulse">
				<RenderOnlyActiveTab tabKey="pulse" tabsActiveKey={tabsActiveKey}>
					<PulseGraph
						serialNumber={product.serialNumber}
						tabsActiveKey={tabsActiveKey}
					/>
				</RenderOnlyActiveTab>
			</Tabs.Tab>

			<Tabs.Tab eventKey="serenity" title="Serenity events">
				<RenderOnlyActiveTab tabKey="serenity" tabsActiveKey={tabsActiveKey}>
					<SupportSerenityAllEventsTable
						minimal={true}
						serialNumber={product.serialNumber}
					/>
				</RenderOnlyActiveTab>
			</Tabs.Tab>

			<Tabs.Tab
				eventKey="notes"
				title={<NotesIcon productNotes={productNotes.length} />}
			>
				<RenderOnlyActiveTab tabKey="notes" tabsActiveKey={tabsActiveKey}>
					<SupportCustomersSupportNotes
						maintenanceNotes={maintenanceService.notes}
						userId={userId}
						productId={product.id}
					/>
				</RenderOnlyActiveTab>
			</Tabs.Tab>
		</Tabs>
	);
};

ActiveCard.propTypes = {
	userId: PropTypes.string.isRequired
};

const mapStateToProps = ({ productNotes }) => {
	return { productNotes: productNotes.notes };
};

const mapDispatchToProps = (dispatch) => {
	return {
		getNotesForProduct: (productId) => { return dispatch(getNotesForProduct(productId)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveCard);
