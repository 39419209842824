import React from 'react';
import { maintenanceDurations } from '../constants/maintenance-durations';
import countries from '../constants/countries';
import provinces from '../constants/provinces';
import marketSectors from '../constants/market-sectors';
import revenue from '../constants/revenue';
import nrOfEmployees from '../constants/number-of-employees';
import userRoles from '../constants/user-roles';
import _ from 'lodash';

import UserService from './User';
import { groupUsersPerCompany } from './normalize-data';

class SelectOptionsRenderer {

	static renderDefaultOption(message) {
		let defaultMessage = message || 'Please select an option';
		return <option>{defaultMessage}</option>;
	}

	static renderDefaultOptionValueMarketSectors() {
		return marketSectors.collection[0];
	}

	static renderDefaultOptionValueRevenue() {
		return revenue.collection[0];
	}

	static renderDefaultOptionValueMarketSectorsDevAccount() {
		return marketSectors.collection.filter((collectionItem) => {
			return collectionItem === 'Information technology';
		});
	}

	static renderDefaultOptionValueCountries() {
		return countries.collection[0];
	}

	static renderDefaultOptionValueCountriesDevAccount() {
		return countries.collection.filter((collectionItem) => {
			return collectionItem === 'Romania';
		});
	}

	static renderDefaultOptionValueNrOfEmployees() {
		return nrOfEmployees.collection[0].employees;
	}

	static renderCompaniesWithUsers(companies) {
		const orderedCompanies = _.orderBy(companies, [(company) => { return company.name.toLowerCase().trim(); }], ['asc']);
		
		return orderedCompanies.map((company) => {
			return {
				label: company.name,
				value: company.name,
				options: company.users.map((user) => {
					return {
						label: `${user.firstName} ${user.lastName}`,
						value: user.id
					}; 
				})
			};
		});
	}

	static renderMaintenanceTypesOptions(maintenanceTypeOptions) {
		const options = [];

		const currentlySellingMaintenanceTypes = maintenanceTypeOptions.filter((option) => {
			return option.currentlySelling === true;
		});

		const notSellingMaintenanceTypes = maintenanceTypeOptions.filter((option) => {
			return option.currentlySelling === false;
		});

		if (currentlySellingMaintenanceTypes.length > 0) {
			options.push({
				label: 'Currently selling',
				options: currentlySellingMaintenanceTypes.map((maintenanceType) => {
					return {
						value: maintenanceType.id,
						label: maintenanceType.synetoInternalName
					}; 
				})
			});
		}

		if (notSellingMaintenanceTypes.length > 0) {
			options.push({
				label: 'Not selling',
				options: notSellingMaintenanceTypes.map((maintenanceType) => {
					return {
						value: maintenanceType.id,
						label: maintenanceType.synetoInternalName
					}; 
				})
			});
		}

		return options;
	}

	static renderMaintenanceDurationsOptions() {
		return maintenanceDurations.durationsList;
	}

	static renderCountriesOptions() {
		return countries.collection.map((country, key) => {
			return <option key={key} value={country}>{country}</option>;
		});
	}

	static renderProvincesOptions() {
		return provinces.collection.map((province, key) => {
			return <option key={key} value={province.abbreviation}>{province.abbreviation} ({province.name})</option>;
		});
	}

	static renderUserRoles() {
		let keysArr = Object.keys(userRoles);
		return keysArr.map((userRole, index) => {
			return <option key={index}
				value={userRoles[userRole]}>{UserService.toUppercaseUserRole(userRoles[userRole])}</option>;
		});
	}

	static renderMarketSectors() {
		return marketSectors.collection.map((marketSector, key) => {
			return <option key={key} value={marketSector}>{marketSector}</option>;
		});
	}

	static renderRevenue() {
		return revenue.collection.map((revenue, key) => {
			return <option key={key} value={revenue}>{revenue}</option>;
		});
	}

	static renderNumberOfEmployees() {
		return nrOfEmployees.collection.map((employeeObj, key) => {
			return <option key={key} value={employeeObj.employees}>{employeeObj.label}</option>;
		});
	}

	static renderProductModel(productModels) {
		const options = [];

		const currentlySellingProducts = productModels.filter((model) => {
			return model.currentlySelling === true;
		});

		const notSellingProducts = productModels.filter((model) => {
			return model.currentlySelling === false;
		});

		if (currentlySellingProducts.length > 0) {
			options.push({
				label: 'Currently selling',
				options: currentlySellingProducts.map((model, key) => {
					return {
						value: model.id,
						label: model.title
					}; 
				})
			});
		}

		if (notSellingProducts.length > 0) {
			options.push({
				label: 'Not selling',
				options: notSellingProducts.map((model, key) => {
					return {
						value: model.id,
						label: model.title
					}; 
				})
			});
		}

		return options;
	}
}

export default SelectOptionsRenderer;
