import moment from 'moment';

class MaintenanceStatusFormatter {
	static maintenanceStatus(maintenanceService) {
		let maintenanceStatus = 'Pending';
	
		if (!maintenanceService || (Array.isArray(maintenanceService) && maintenanceService.length === 0)) {
			return maintenanceStatus;
		}
	
		const checkStatus = (service) => {
			let expirationDate = service.expiresOn
				? moment(service.expiresOn)
				: moment(service);
	
			let maintenanceStatusFormatted = expirationDate.add(1, 'day').format('YYYY-MM-DD');
			return moment().isBefore(maintenanceStatusFormatted) ? 'Active' : 'Expired';
		};
	
		if (Array.isArray(maintenanceService)) {
			for (let service of maintenanceService) {
				if (!service.expiresOn) { continue; }
				maintenanceStatus = checkStatus(service);
				if (maintenanceStatus === 'Active') { break; }
			}
		} else {
			maintenanceStatus = checkStatus(maintenanceService);
		}
	
		return maintenanceStatus;
	}	

	static setExpiresOnBadge(status) {
		switch (status) {
			case 'Active':
				return 'secondary';
			case 'Expired':
				return 'danger';
		}
	}

	static setStyle(status) {
		switch (status) {
			case 'Active':
				return 'success';
			case 'Pending':
				return 'warning';
			case 'Expired':
				return 'danger';
		}
	}

	static setStyleAddon(status) {
		switch (status) {
			case 'Active':
				return 'success';
			case 'Terminated':
				return 'danger';
		}
	}

	static setTooltipInformation(expiresOn) {
		let tooltipInformation = '';

		if ( !expiresOn ) {
			tooltipInformation = 'Pending. Product not activated yet!';
		} else {
			let expiresOnFormatted = moment(expiresOn).format('YYYY-MM-DD');
			if ( moment().isBefore(expiresOnFormatted) ) {
				tooltipInformation = `Expires on ${ expiresOnFormatted }`;
			} else {
				tooltipInformation = `Expired on ${ expiresOnFormatted }`;
			}
		}

		return tooltipInformation;
	}

	static showTooltipDate(expiresOn) {
		if ( !expiresOn ) {
			return 'Pending. Product not activated yet!';
		}

		if ( moment().isBefore(moment(expiresOn, 'DD/MM/YYYY')) ) {
			return `Expires on ${ expiresOn }`;
		} else {
			return `Expired on ${ expiresOn }`;
		}
	}
}

export default MaintenanceStatusFormatter;
