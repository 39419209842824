export const LOADING_MAINTENANCE_SERVICES = 'LOADING_MAINTENANCE_SERVICES';

export const LOADING_PRODUCT_MAINTENANCE_SERVICES_SUCCESS = 'LOADING_PRODUCT_MAINTENANCE_SERVICES_SUCCESS';
export const LOADING_PRODUCT_MAINTENANCE_SERVICES_ERROR = 'LOADING_PRODUCT_MAINTENANCE_SERVICES_ERROR';

export const LOADING_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS = 'LOADING_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS';
export const LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS = 'LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS';
export const LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_ERROR = 'LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_ERROR';
export const RESET_PRODUCT_MAINTENANCE_SERVICE_DETAILS = 'RESET_PRODUCT_MAINTENANCE_SERVICE_DETAILS';

export const LOAD_OPERATIONS_MAINTENANCE_DETAILS_SUCCESS = 'LOAD_MAINTENANCE_DETAILS_OPERATIONS_SUCCESS';
export const LOAD_OPERATIONS_MAINTENANCE_DETAILS_ERROR = 'LOAD_MAINTENANCE_DETAILS_OPERATIONS_ERROR';
