import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@syneto/compass-react';

export const WarningModal = ({ content, showWarningModal, closeWarningModal, onSubmit }) => {
	return (
		<Modal
			show={showWarningModal}
			onHide={closeWarningModal}
			title="Warning"
			content={content}
			actions={{
				primary: {
					label: 'Continue',
					onClick: onSubmit
				},
				secondary: {
					label: 'Cancel'
				}
			}}
		/>
	);
};

WarningModal.propTypes = {
	content: PropTypes.string,
	showWarningModal: PropTypes.bool.isRequired,
	closeWarningModal: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired
};
