import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from '@syneto/compass-react';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';
import { deleteProducts } from '../../../../services/products';
import { getJob } from '../../../../services/jobs';

const DeleteProductModal = (props) => {
	const { onHide, onDelete, products, pushDangerNotification, pushSuccessfulNotification } = props;
	const [content, setContent] = useState(null);
	const [isDeleting, setIsDeleting] = useState(false);

	const onSubmit = useCallback(() => {
		const waitForJob = (jobId) => {
			getJob(jobId)
				.then((jobResponse) => {
					if (jobResponse.data.status.toLowerCase() === 'succeeded') {
						setIsDeleting(false);
						pushSuccessfulNotification('Product(s) deleted successfully');
						onHide();
						if (typeof onDelete === 'function') {
							setTimeout(onDelete, 2000);	
						}
					} else if (jobResponse.data.status.toLowerCase() === 'failed') {
						setIsDeleting(false);
						pushDangerNotification('An error occurred while deleting the product(s)');
					} else {
						setTimeout(waitForJob, 1000, jobId);
					}
				})
				.catch((error) => {
					setIsDeleting(false);
					pushDangerNotification(`An error occurred while deleting the product(s): ${error}`);
					onHide();
				});
		};

		setIsDeleting(true);
		deleteProducts(products)
			.then((response) => {
				waitForJob(response.data.jobId);
			})
			.catch((error) => {
				pushDangerNotification(`An error occurred while deleting the product(s): ${error}`);
				setIsDeleting(false);
			});
	}, [products]);

	useEffect(() => {
		if (products.length === 1) {
			const { serialNumber } = products[0];
			setContent(<p className="text-value">You are going to delete product <strong>{serialNumber}</strong>. Are you sure?</p>);
		} else {
			setContent('You are going to delete several products. Are you sure?');
		}
	}, [products]);

	return (
		<Modal
			show={true}
			onHide={onHide}
			title={products.length === 1 ? 'Delete product' : 'Delete products'}
			content={content}
			actions={
				{
					primary: {
						disabled: isDeleting,
						label: isDeleting ? 'Deleting...' : 'Delete',
						onClick: onSubmit
					},
					secondary: {
						label: 'Cancel',
						onClick: onHide
					}
				}
			}
		/>
	);
};

export default connect(null, { pushSuccessfulNotification, pushDangerNotification })(DeleteProductModal);

DeleteProductModal.propTypes = {
	onHide: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	products: PropTypes.array.isRequired
};
