import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Icon, Table, Tooltip } from '@syneto/compass-react';
import Preloader from '../../../../components/preloader';
import { handleSort } from '../../../../helpers/TableUtilities';
import MaintenanceStatusFormatter from '../../../../helpers/MaintenanceStatusFormatter';

const processTableData = (addons) => {
	return addons.map((addon) => {
		return {
			name: addon.name ?? '',
			id: addon.id ?? '',
			summary: addon.summary ?? '',
			trialDuration: addon.trialDuration ?? '',
			featurePeriod: addon.featurePeriod ?? '',
			supportedProductVersions: addon.supportedProductVersions ?? [],
			status: addon.status
		};
	});
};

const OverflowTip = ({ children }) => {
	const [isOverflowed, setIsOverflow] = useState(false);
	const textElementRef = useRef();
	useEffect(() => {
		setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
	}, []);
	return (
		<Tooltip text={children} placement="top" trigger={isOverflowed ? 'hover' : ''}>
			<div
				ref={textElementRef}
				style={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				{children}
			</div>
		</Tooltip>
	);
};

export const ProductAddonsTable = (props) => {
	const { loading, addons, setEditedAddon, setItemToRemove } = props;

	const handleTableChange = (type, { page, sizePerPage, searchText, sortOrder, sortField, data }) => {
		switch (type) {
			case 'sort': {
				handleSort(sortOrder, sortField, data);
				return;
			}
		}
	};

	const openEditModal = (selectedAddonId) => {
		const selectedAddon = addons.find((addon) => { return addon.id === selectedAddonId; });

		if (selectedAddon) {
			setEditedAddon(selectedAddon);
		}
	};

	const renderSummary = (cell, row) => {
		return (
			<>
				<OverflowTip>{row.summary}</OverflowTip>
			</>
		);
	};

	const renderSupportedVersions = (cell, row) => {
		return (
			<>
				<OverflowTip>{formatSupportedVersions(row.supportedProductVersions)}</OverflowTip>
			</>
		);
	};

	const formatSupportedVersions = (versions) => {
		return versions.map((version, index) => {
			return index ? ', ' + version : version;
		});
	};

	const renderStatus = (cell, row) => {
		return (
			<>
				<Badge appearance={MaintenanceStatusFormatter.setStyleAddon(row.status)}>
					{row.status}
				</Badge>
			</>
		);
	};

	const renderActions = (cell, row) => {
		return (
			<>
				<Button role="tertiary" onClick={() => { openEditModal(row.id); }}>
					Edit
				</Button>
				<Button role="tertiary" onClick={() => { setItemToRemove(new Array(row)); }}>
					Delete
				</Button>
			</>
		);
	};

	const sortPeriods = (a, b, order) => {
		const extractNumber = (str) => {
			let match = str.match(/^\d+/);
			return match ? parseInt(match[0], 10) : 0;
		};
		a = extractNumber(a);
		b = extractNumber(b);
		return (order === 'desc' ? b - a : a - b);
	};

	const emptyTableState = () => {
		return loading
			? <Preloader dataIsLoading={true} />
			: 'No product add-ons found';
	};

	return (
		<div className="p-4 bg-white">
			<Table
				data={processTableData(addons)}
				keyField="id"
				selectable="multiple"
				// search={{ defaultSearch: queryParams.searchFor }}
				actions={[{
					label: 'Delete selected',
					onClick: (selected) => { return setItemToRemove(selected); }
				}]}
				onTableChange={handleTableChange}
				noDataIndication={emptyTableState()}
			>
				<Table.Col field="name" align="left" sort>Name</Table.Col>
				<Table.Col field="summary" align="left" sort renderCell={renderSummary}>Summary</Table.Col>
				<Table.Col field="trialDuration" align="left" sort={sortPeriods}>Trial duration</Table.Col>
				<Table.Col field="featurePeriod" align="left" sort={sortPeriods}>Feature period</Table.Col>
				<Table.Col field="status" align="left" sort renderCell={renderSupportedVersions}>Versions</Table.Col>
				<Table.Col field="status" align="left" sort renderCell={renderStatus}>Status</Table.Col>
				<Table.Col align="center" renderCell={renderActions}>Actions</Table.Col>
			</Table>
		</div>
	);
};

ProductAddonsTable.propTypes = {
	addons: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired
};
