import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@syneto/compass-react';

const renderDate = (value) => {
	return new Date(value).toLocaleDateString();
};

const renderDuration = (value, row) => {
	return `${value} ${row.durationType}`;
};

const renderTier = (value, row) => {
	return row.addon.addonTiers.find((tier) => { return tier.id === value; } )?.name;
};

const ExpiredProductAddons = ({ addons }) => {
	return (
		<Table data={addons} keyField='id'>
			<Table.Col field="addon.name" align="left">
				Add-on Name
			</Table.Col>
			<Table.Col field="startDate" align="left" renderCell={renderDate}>
				Start date
			</Table.Col>
			<Table.Col field="endDate" align="left" renderCell={renderDate}>
				Expiration date
			</Table.Col>
			<Table.Col field="duration" align="left" renderCell={renderDuration}>
				Duration
			</Table.Col>
			<Table.Col field="tierId" align="left" renderCell={renderTier}>
				Tier
			</Table.Col>
		</Table>
	);
};

export default ExpiredProductAddons;
ExpiredProductAddons.propTypes = {
	addons: PropTypes.array.isRequired,
};
