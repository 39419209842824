import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Badge, Modal, Button, Icon } from '@syneto/compass-react';
import Form from '../../../../components/forms/form';
import SelectOptionsRenderer from '../../../../helpers/SelectOptionsRenderer';
import SerenityField from '../../../../components/forms/common/serenity-field';
import { getDurationOption } from '../../../../constants/maintenance-durations';
import { connect } from 'react-redux';

const ProductMaintenancePlan = (props) => {
	const { maintenancePlansOptions, maintenanceService, isRenewal, change, changeMaintenanceService, extension, index, deleteMaintenanceService } = props;
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [maintenanceType, setMaintenanceType] = useState(maintenancePlansOptions.find((option) => { return option.id === maintenanceService?.maintenancePlanId; }) ?? null);
	const changeMaintenanceType = (id) => {
		setMaintenanceType(maintenancePlansOptions.find((option) => { return option.id === id; }));
		changeMaintenanceService({ ...maintenanceService, maintenancePlanId: id }, maintenanceService.uuid);
	};
	
	return (
		<>
			<Row>
				<Col xs={1}>
					{<>
						{!isRenewal && (
							<Row className="mb-2">
								<b>Type</b>
							</Row>
						)}
						<Row>
							<Col className="d-flex center">
								{isRenewal ? (<Badge appearance="success">R</Badge>) : (<Badge appearance="info">I</Badge>)}
							</Col>
						</Row>
					</>}
				</Col>

				<Col xs={3}>
					{<Form.select
						{... !isRenewal ? { label: 'Support level' } : {}}
						name={`maintenanceServices[${index}].maintenancePlanId`}
						options={SelectOptionsRenderer.renderMaintenanceTypesOptions(maintenancePlansOptions)}
						emptyOption="Please select"
						input={{ value: maintenanceType?.id, onChange: changeMaintenanceType }}
					/>}
				</Col>

				<Col xs={1}>
					{<>
						{!isRenewal && (
							<Row className="mb-2">
								<b>Serenity</b>
							</Row>
						)}
						<Row>
							<Col className="d-flex center">
								<SerenityField
									name={`maintenanceServices[${index}].hasSerenity`}
									value={maintenanceService?.hasSerenity ?? false}
									maintenanceType={maintenanceType?.id}
									changePropsInParent={change} />
							</Col>
						</Row>
					</>}
				</Col>

				<Col xs={2}>
					{<Form.select
						{... !isRenewal ? { label: 'Duration' } : {}}
						name={`maintenanceServices[${index}].duration`}
						options={SelectOptionsRenderer.renderMaintenanceDurationsOptions()}
						emptyOption="Please select"
						input={{ value: getDurationOption(maintenanceService?.duration)?.value, onChange: (value) => { changeMaintenanceService({ ...maintenanceService, duration: value }, maintenanceService.uuid); } }}
					/>}
				</Col>

				<Col xs={2}>
					{<Form.text
						{... !isRenewal ? { label: 'Quote number' } : {}}
						className="text-value"
						id={`maintenanceServices[${index}].quoteNumber`}
						name={`maintenanceServices[${index}].quoteNumber`}
						required
						input={{ defaultValue: maintenanceService?.quoteNumber, onChange: (event) => { changeMaintenanceService({ ...maintenanceService, quoteNumber: event.target.value }, maintenanceService.uuid); } }}
					/>}
				</Col>

				<Col xs={2}>
					{<Form.text
						{... !isRenewal ? { label: 'Sale value' } : {}}
						className="text-value"
						id={`maintenanceServices[${index}].saleValue`}
						name={`maintenanceServices[${index}].saleValue`}
						input={{ defaultValue: maintenanceService?.saleValue, onChange: (event) => { changeMaintenanceService({ ...maintenanceService, saleValue: event.target.value }, maintenanceService.uuid); } }}
					/>}
				</Col>

				{isRenewal && (
					<Col xs={1}>
						<Button
							role="tertiary"
							onClick={() => { return setShowModalDelete(true); }}
						>
							<Icon name="fa fa-trash" className="text-muted" />
						</Button>
					</Col>
				)}
			</Row>
			{isRenewal && (
				<Modal
					show={showModalDelete}
					onHide={() => { return setShowModalDelete(false); }}
					title="Delete extension plan"
					content={
						<>
							{
								extension && extension.quoteNumber
									?
									<p>Are you sure you want to delete the extension plan with the following quote
										number: {extension.quoteNumber}?</p>
									: <p>Are you sure you want to delete this extension plan?</p>
							}
						</>
					}
					actions={{
						primary: {
							label: 'Delete',
							onClick: () => {
								deleteMaintenanceService(maintenanceService?.uuid);
								setShowModalDelete(false);
							}
						},
						secondary: {
							label: 'Cancel',
							onClick: () => {
								setShowModalDelete(false);
							}
						}
					}}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		productAddons: state.productAddons.data,
		productModels: state.productModels.models,
		companiesWithEndUsers: state.companies.companiesWithEndUsers,
		maintenancePlansOptions: state.maintenancePlansOptions.maintenancePlansOptions.currentlySelling,
	};
};

export default connect(mapStateToProps, {})(ProductMaintenancePlan);

ProductMaintenancePlan.propTypes = {
	isRenewal: PropTypes.bool,
};
