class Utils {
	static toUppercase(str) {
		let words = [];
		for (let word of str.split(' ')) {
			words.push(word[0].toUpperCase() + word.slice(1));
		}

		return words.join(' ');
	}

	/**
   * Normalizes the saleValue from ddd.ddd.dd | ddd.ddd,dd | ddd,ddd,dd to dddddd.dd
   * Usage: normalizeSaleValue(1.345.3) -> returns: 1345.3
   * @param saleValue {string|number}
   * @returns {string|boolean|*}
   */
	static normalizeSaleValue(saleValue) {
		try {
			saleValue = saleValue.toString();
			let saleValueWithNoCommas = saleValue.replace(/,/g, '.');
			let splittedSaleValue = saleValueWithNoCommas.split('.');

			if (splittedSaleValue.length > 1) {
				return splittedSaleValue.slice(0, -1).join('') + '.' + splittedSaleValue.slice(-1).join('');
			} else {
				return saleValueWithNoCommas;
			}

		} catch (e) {
			return null;
		}
	}

	static uuidv4 = () => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			const r = Math.random() * 16 | 0;
			const v = c === 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	};

}

export default Utils;
