import { axios } from '../../services/httpClient';
import * as actionTypes from './actionTypes';
import _ from 'lodash';

export const loadProductMaintenanceServicesForSupport = () => {
	return (dispatch) => {
		dispatch(loadingMaintenanceServices());
		return axios.get('/support/products/maintenance')
			.then((response) => {
				dispatch(loadingProductMaintenanceServicesSuccess(response.data));
			}).catch((error) => {
				dispatch(loadingProductMaintenanceServicesError(error));
			});
	};
};

const loadingMaintenanceServices = () => {
	return {
		type: actionTypes.LOADING_MAINTENANCE_SERVICES
	};
};

const loadingProductMaintenanceServicesSuccess = (data) => {
	return {
		type: actionTypes.LOADING_PRODUCT_MAINTENANCE_SERVICES_SUCCESS,
		payload: {
			maintenanceServices: data.maintenanceServices,
			maxMaintenanceExtension: data.maxMaintenanceExtension
		}
	};
};

const loadingProductMaintenanceServicesError = (error) => {
	return {
		type: actionTypes.LOADING_PRODUCT_MAINTENANCE_SERVICES_ERROR,
		payload: error
	};
};

export const loadProductMaintenanceServiceDetails = (maintenanceServiceId) => {
	return (dispatch) => {
		dispatch(loadingProductMaintenanceServiceDetails());
		return axios.get(`/support/maintenance/${maintenanceServiceId}`)
			.then((response) => {
				if (response.status === 200) {
					dispatch(loadProductMaintenanceServiceDetailsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(loadProductMaintenanceServiceDetailsError(error?.response?.data?.message));
			});
	};
};

const loadingProductMaintenanceServiceDetails = () => {
	return {
		type: actionTypes.LOADING_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS
	};
};

const loadProductMaintenanceServiceDetailsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_SUCCESS,
		payload: data.maintenanceService
	};
};

const loadProductMaintenanceServiceDetailsError = (error) => {
	return {
		type: actionTypes.LOAD_PRODUCT_MAINTENANCE_SERVICE_DETAILS_ERROR,
		payload: error
	};
};

export const loadMaintenanceDetailsOperations = (maintenanceId) => {
	return (dispatch) => {
		return axios.get(`/operations/maintenance/${maintenanceId}`)
			.then((response) => {
				if (response.status === 200) {
					dispatch(loadMaintenanceDetailsOperationsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(loadMaintenanceDetailsOperationsError(error?.response?.data?.message));
			});
	};
};

const loadMaintenanceDetailsOperationsSuccess = (data) => {
	return {
		type: actionTypes.LOAD_OPERATIONS_MAINTENANCE_DETAILS_SUCCESS,
		payload: data.maintenanceService
	};
};

const loadMaintenanceDetailsOperationsError = (error) => {
	return {
		type: actionTypes.LOAD_OPERATIONS_MAINTENANCE_DETAILS_ERROR,
		payload: error.message
	};
};

export const resetMaintenanceServiceDetails = () => {
	return {
		type: actionTypes.RESET_PRODUCT_MAINTENANCE_SERVICE_DETAILS
	};
};
