export const START_FETCHING_PRODUCT_ADDONS = 'START_FETCHING_PRODUCT_ADDONS';
export const GET_PRODUCT_ADDONS_SUCCESS = 'GET_PRODUCT_ADDONS_SUCCESS';
export const GET_PRODUCT_ADDONS_ERROR = 'GET_PRODUCT_ADDONS_ERROR';

export const START_FETCHING_PRODUCT_ADDON_DETAILS = 'START_FETCHING_PRODUCT_ADDON_DETAILS';
export const GET_PRODUCT_ADDON_DETAILS_SUCCESS = 'GET_PRODUCT_ADDON_DETAILS_SUCCESS';
export const GET_PRODUCT_ADDON_DETAILS_ERROR = 'GET_PRODUCT_ADDON_DETAILS_ERROR';
export const REMOVE_PRODUCT_ADDON_DETAILS = 'REMOVE_PRODUCT_ADDON_DETAILS';

export const SHOW_ADD_PRODUCT_ADDON_MODAL = 'SHOW_ADD_PRODUCT_ADDON_MODAL';
export const HIDE_ADD_PRODUCT_ADDON_MODAL = 'HIDE_ADD_PRODUCT_ADDON_MODAL';
