import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Tabs } from '@syneto/compass-react';
import { getCustomerDetails } from '../../../../../data/selected-customer/actions';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../../data/notifications/actions';
import ResultItemCard from '../../ResultItemCard';
import ActiveCard from '../CustomersMaintenance/ActiveCard';
import TabServices from './tabs/Services';
import Preloader from '../../../../../components/preloader';
import MonitoringInfo from './tabs/MonitoringInfo';
import RenderOnlyActiveTab from '../../../../../components/RenderOnlyActiveTab';

const CustomersTableSearchResults = (props) => {
	const { customers, query, userId, userData, user, profile, products, company, supportTunnels, loadingUserProducts, maintenanceServices, pushDangerNotification, pushSuccessfulNotification, getCustomerDetails } = props;
	const [customer, setCustomer] = useState();
	const [assemblyTests, setAssemblyTests] = useState();
	const [tabsActiveKey, setTabsActiveKey] = useState();
	const [selectedProduct, setSelectedProduct] = useState(null);

	useEffect(() => {
		getCustomerDetails(userId);
		getCustomerProducts(userId);
	}, [userId]);

	const getCustomerProducts = (userId) => {
		let customer = customers.find((customer) => { return customer.iamUserId === userId; });
		setCustomer(customer);
	};

	useEffect(() => {
		if (customer && selectedProduct) {
			let product = customer?.products?.find((product) => { return product?.serialNumber === selectedProduct?.serialNumber; });
			if (product && product.assemblyTests && product.assemblyTests.length) {
				setAssemblyTests(product.assemblyTests[0]);
			}
		}
	}, [selectedProduct]);

	useEffect(() => {
		if (products.length <= 0 || !products) {
			setSelectedProduct(null);
		}

		const searchedProduct = products.find((product) => { return product.serialNumber === query.searchFor; });
		
		if (searchedProduct) {
			setSelectedProduct(searchedProduct);
		} else if (products.length > 0) {
			setSelectedProduct(products[0]);
		}
		
	}, [products, query]);

	const copyCompanyDetails = () => {
		const name = document.getElementById('name');
		const companyEmail = document.getElementById('companyEmail');
		const phoneNumber = document.getElementById('phoneNumber');
		const company = document.getElementById('company');
		const address = document.getElementById('address');
		const postalCode = document.getElementById('postalCode');
		const city = document.getElementById('city');
		const province = document.getElementById('province');
		const country = document.getElementById('country');

		const text =
			'Name: ' + name.value + '\n' +
			'Company email: ' + companyEmail.value + '\n' +
			'Phone number: ' + phoneNumber.value + '\n' +
			'Company: ' + company.value + '\n' +
			'Address: ' + address.value + '\n' +
			'Postal code: ' + postalCode.value + '\n' +
			'City: ' + city.value + '\n' +
			'Province: ' + province.value + '\n' +
			'Country: ' + country.value;

		copyToClipboard(text);
	};

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		pushSuccessfulNotification('Copied to the clipboard.');
	};

	const changeSelectedProduct = (key) => {
		setSelectedProduct(products[key]);
	};

	const renderUserProducts = (products, maintenanceServices) => {
		if (!products.length) {
			return <p className="ms-3 font-weight-bold mt-1" style={{ fontSize: '14px' }}>There are no products associated
				with
				this account.</p>;
		}

		return products.map((product, key) => {
			return (
				<a className="row" key={product.id} onClick={() => { return changeSelectedProduct(key); }}>
					<Col>
						<ResultItemCard
							product={product}
							maintenanceServices={maintenanceServices}
							selected={product.id === selectedProduct?.id}
							query={query}
						/>
					</Col>
				</a>
			);
		});
	};

	return (
		<div>
			{!user || !company || !profile ?
				null :
				<div className="mt-4">
					{loadingUserProducts ?
						<Preloader dataIsLoading={loadingUserProducts} /> :

						<Tabs activeKey={ tabsActiveKey } onSelect={ (tabsActiveKey) => { return setTabsActiveKey(tabsActiveKey); } }>
							<Tabs.Tab eventKey="products" title="Products and maintenance plans">
								<div className="row m-0">
									<div className="scrollable-content col-5">
										{renderUserProducts(products, maintenanceServices)}
									</div>
									<div className="col-7">
										{selectedProduct &&
											<ActiveCard
												product={selectedProduct}
												maintenanceServices={maintenanceServices}
												userId={user.id}
												userData={userData}
												supportTunnels={supportTunnels}
												assemblyTests={assemblyTests}
											/>
										}
									</div>
								</div>
							</Tabs.Tab>

							<Tabs.Tab eventKey="account" title="Account information">
								<div className="container mt-3 ps-0 pf-0">
									<div className="row">
										<div className="col-6">
											<dl className="row account-information-support">
												<dt className="col-3">Name</dt>
												<input id="name" className="col-9 border-0"
													value={`${(profile.firstName)} ${(profile.lastName)}`}
													type="text" readOnly />

												<dt className="col-3">Email</dt>
												<input id="email" className="col-9 border-0" value={user.email}
													type="text" readOnly />

												<dt className="col-3">Company email</dt>
												<input id="companyEmail" className="col-9 border-0" value={company.email}
													type="text" readOnly />

												<dt className="col-3">Phone number</dt>
												<input id="phoneNumber" className="col-9 border-0" type="text" readOnly
													value={profile.phoneNumber ? profile.phoneNumber : 'Not available.'} />
												<dt className="col-3">Support Contact</dt>
												<input id="phoneNumber" className="col-9 border-0" type="text" readOnly
													value={user.isSupportContact ? 'yes' : 'no'} />
											</dl>
										</div>
										{company && (
											<div className="col-6">
												<dl className="row account-information-support">
													<dt className="col-3">Company</dt>
													<input id="company" className="col-9 border-0" type="text" readOnly
														value={company.name ? company.name : 'Not available.'} />

													<dt className="col-3">Address</dt>
													<input id="address" className="col-9 border-0" type="text" readOnly
														value={company.address ? company.address : 'Not available.'} />

													<dt className="col-3">Postal code</dt>
													<input id="postalCode" className="col-9 border-0" type="text" readOnly
														value={company.postalCode ? company.postalCode : 'Not available.'} />

													<dt className="col-3">City</dt>
													<input id="city" className="col-9 border-0" type="text" readOnly
														value={company.city ? company.city : 'Not available.'} />

													<dt className="col-3">Province</dt>
													<input id="province" className="col-9 border-0" type="text" readOnly
														value={company.province ? company.province : 'Not available.'} />

													<dt className="col-3">Country</dt>
													<input id="country" className="col-9 border-0" type="text" readOnly
														value={company.country ? company.country : 'Not available.'} />

													<dt className="col-3">Market sector</dt>
													<dd className="col-9">{company.marketSector ? company.marketSector : 'Not available.'}</dd>

													<dt className="col-3">Employees</dt>
													<dd className="col-9">{company.numberOfEmployees ? company.numberOfEmployees : 'Not available.'}</dd>
												</dl>
											</div>
										)}
									</div>
								</div>
								<Button className="p-0 me-2 mb-3" role="tertiary" onClick={copyCompanyDetails}>
									Copy contact details to clipboard
								</Button>
							</Tabs.Tab>

							
							<Tabs.Tab eventKey="monitoring" title="Monitoring Info">
								<RenderOnlyActiveTab tabKey="monitoring" tabsActiveKey={tabsActiveKey}>
									<MonitoringInfo copyToClipboard={copyToClipboard} />
								</RenderOnlyActiveTab>
							</Tabs.Tab>
								

							<Tabs.Tab eventKey="services" title="Services">
								<TabServices companyId={company.id} pushDangerNotification={pushDangerNotification} />
							</Tabs.Tab>
						</Tabs>
					}
				</div>
			}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		customers: state.customers?.data?.customers,
		loadingUserProducts: state.selectedCustomer.loading,
		user: state.selectedCustomer.user,
		products: state.selectedCustomer.products,
		company: state.selectedCustomer.company,
		profile: state.selectedCustomer.profile,
		maintenanceServices: state.productMaintenanceService.maintenanceServices,
		supportTunnels: state.supportTunnels.supportTunnels
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCustomerDetails: (userId) => { return dispatch(getCustomerDetails(userId)); },
		pushSuccessfulNotification: (message) => { return dispatch(pushSuccessfulNotification(message)); }, 
		pushDangerNotification: (message) => { return dispatch(pushDangerNotification(message)); }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersTableSearchResults);
