import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Button, Tooltip, Badge } from '@syneto/compass-react';
import Preloader from '../../../../components/preloader';
import { pushDangerNotification, pushSuccessfulNotification } from '../../../../data/notifications/actions';

import { getCustomersSupport as getCustomers } from '../../../../data/customers/action';
import { updateToMasterUser } from './service';
import { GET_CUSTOMERS_DEFAULT_QUERY_PARAMS } from '../../../../constants/queryParams';

const AdminCustomers = (props) => {
	let { loading, customers, getCustomers, pagination, pushDangerNotification, pushSuccessfulNotification } = props;

	const [queryParams, setQueryParams] = useState(GET_CUSTOMERS_DEFAULT_QUERY_PARAMS);
	useEffect(() => {
		getCustomers(queryParams);
	}, [queryParams]);

	const handleSearch = (value) => {
		setQueryParams(
			{
				searchFor: value,
				start: 0,
				limit: 30
			});
	};

	const debounce = (func, delay) => {
		let timerId;
		return function() {
			const context = this;
			const args = arguments;
			clearTimeout(timerId);
			timerId = setTimeout(() => {
				func.apply(context, args);
			}, delay);
		};
	};

	const debouncedHandleSearch = debounce(handleSearch, 500);

	const handlePagination = (page, sizePerPage) => {
		const start = (page - 1) * sizePerPage;

		setQueryParams({
			...queryParams,
			start: start >= 0 ? start : 0,
			limit: sizePerPage
		});
	};

	const handleTableChange = (type, { page, sizePerPage, searchText, sortOrder, sortField, data }) => {
		switch (type) {
			case 'pagination': {
				handlePagination(page, sizePerPage);
				return;
			}
			case 'search': {
				debouncedHandleSearch(searchText);
				return;
			}

		}
	};

	const renderName = (cell, row) => { return `${row.firstName} ${row.lastName}`; };

	const renderAccountType = (cell, row) => {
		return (
			<>
				{row.isMasterAccount ? <Tooltip text="Master Account" placement="top">
					<Badge appearance="info">MA</Badge>
				</Tooltip> : <Tooltip text="Regular User" placement="top">
					<Badge appearance="info">RU</Badge>
				</Tooltip>}
				{row.isSupportContact && <Tooltip text="Support Contact" placement="top">
					<Badge appearance="info">SC</Badge>
				</Tooltip>}
			</>
		);
	};

	const renderCellActions = (cell, row) => {
		return (
			<>
				{!row.isMasterAccount ? <Button role="tertiary" onClick={() => { return setMasterUser(row.iamUserId); }}>
					Set Master User
				</Button> : null
				}
				
			</>
		);
	};

	const setMasterUser = (id) => {
		updateToMasterUser(id)
			.then((response) => {
				getCustomers(queryParams);
				pushSuccessfulNotification(response.data.message);
			})
			.catch((error) => {
				pushDangerNotification(error.message);
			});
	};

	return (
		<div id="customers" className="p-4 bg-white">
			{
				
				<Table
					data={customers.customers ?? []}
					keyField='id'
					search
					remote
					pagination={{
						sizePerPageList: [15, 30, 60, 100, 200],
						sizePerPage: pagination?.itemsPerPage,
						showTotal: true,
						page: pagination?.currentPage,
						totalSize: pagination?.totalItems
					}}
					onTableChange={handleTableChange}
				>
					<Table.Col field="name" renderCell={renderName}>Name</Table.Col>
					<Table.Col field="user.email" sort>Email</Table.Col>
					<Table.Col field="company.email" sort>Company email</Table.Col>
					<Table.Col field="company.name" sort>Company</Table.Col>
					<Table.Col field="company.marketSector" sort>Market sector</Table.Col>
					<Table.Col field="accountType" renderCell={renderAccountType}>Type</Table.Col>
					<Table.Col align="center" renderCell={renderCellActions}>Actions</Table.Col>
				</Table> 
				
			}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		customers: state.customers.data ?? [],
		pagination: state.customers.data.pagination
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCustomers: (queryParams) => { return dispatch(getCustomers(queryParams)); },
		pushDangerNotification: (message) => { dispatch(pushDangerNotification(message)); },
		pushSuccessfulNotification: (message) => { dispatch(pushSuccessfulNotification(message)); }
	};
};

AdminCustomers.propTypes = {
	customers: PropTypes.array.isRequired,
	pagination: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCustomers);
