import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@syneto/compass-react';
import { connect } from 'react-redux';
import { reduxForm, submit } from 'redux-form';
import { onHideModalAddProductAddon } from '../../../../../data/productAddons/actions';
import { ModalBody } from './ModalBody';
import { createProductAddon } from '../../../../../services/products';
import { pushSuccessfulNotification, pushDangerNotification } from '../../../../../data/notifications/actions';
import AddonValidationService from '../../../validation/AddonValidationService';
import { restAxios } from '../../../../../services/httpClient';
import { getJob } from '../../../../../services/jobs';

const FORM_NAME = 'Addon';

const NewModal = (props) => {
	const { onHideModalAddProductAddon, afterUpdateCallback, pushSuccessfulNotification, pushDangerNotification } = props;
	const { handleSubmit, change } = props;
	const [versions, setVersions] = useState([]);
	const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
	const [uploadedImage, setUploadedImage] = useState('');

	const formatSubmitData = (data) => {
		data = { 
			...data,
			posterImage: (uploadedImage && uploadedImage.length) ? uploadedImage : null
		};

		return data;
	};

	const submit = (values) => {
		let addonData = formatSubmitData(values);
		setSubmitButtonLoading(true);

		createProductAddon(addonData)
			.then((updateResponse) => {
				const jobId = updateResponse?.data?.jobId;
				
				function pollJobStatus() {
					getJob(jobId)
						.then((jobResponse) => {
							if (jobResponse.data.status.toLowerCase() === 'succeeded' || jobResponse.data.status.toLowerCase() === 'failed') {
								afterUpdateCallback();
							} else {
								setTimeout(pollJobStatus, 1000);
							}
						})
						.catch((error) => {
							setTimeout(pollJobStatus, 1000);
						});
				}

				pollJobStatus();

				setSubmitButtonLoading(false);
				onHideModalAddProductAddon();
				pushSuccessfulNotification('Add-on successfully created.');
			})
			.catch((error) => {
				setSubmitButtonLoading(false);
				pushDangerNotification(error?.response?.data?.detail || error?.message);
			});
	};

	const cancelAddModal = () => {
		onHideModalAddProductAddon();
	};

	useEffect(() => {
		restAxios.get('/versioning/releases').then((response) => {
			setVersions(response.data);
		});
	}, []);

	return (
		<form id="product-addon" className="form modal fade">
			<Modal
				enforceFocus={false}
				show={true}
				onHide={onHideModalAddProductAddon}
				content={<ModalBody uploadedImage={uploadedImage} setUploadedImage={setUploadedImage} versions={versions} change={change}/>}
				title="New add-on"
				size="lg"
				actions={{
					primary: {
						label: !submitButtonLoading ? 'Save' : 'Saving...',
						onClick: handleSubmit(submit),
						disabled: submitButtonLoading,
					},
					secondary: {
						label: 'Cancel',
						onClick: cancelAddModal,
						disabled: submitButtonLoading,
					},
				}}
			/>
		</form>
	);
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onHideModalAddProductAddon: () => {
			return dispatch(onHideModalAddProductAddon());
		},
		pushSuccessfulNotification: (message) => {
			return dispatch(pushSuccessfulNotification(message));
		},
		pushDangerNotification: (message) => {
			return dispatch(pushDangerNotification(message));
		},
	};
};

const NewModalForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	validate: AddonValidationService.validate,
	onSubmit: submit,
	shouldValidate: () => {
		return true;
	},
})(NewModal);

export default connect(mapStateToProps, mapDispatchToProps)(NewModalForm);

NewModal.propTypes = {
	afterUpdateCallback: PropTypes.func.isRequired,
};
