import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ProductsTable } from './ProductsTable';
import { getProducts } from '../../../../data/product/actions';
import { GET_PRODUCTS_DEFAULT_QUERY_PARAMS } from '../../../../constants/queryParams';
import { getCompaniesWithEndUsers } from '../../../../data/companies/action';
import { loadProductModels } from '../../../../data/productModels/action';
import { getMaintenancePlansOptions } from '../../../../data/maintenanceType/action';
import DeleteProductModal from '../Products/DeleteProductModal';

const ProductsPage = (props) => {
	const { products, pagination, loading, getProducts, getCompaniesWithEndUsers, getProductModels, getMaintenancePlansOptions } = props;
	const [itemToRemove, setItemToRemove] = useState([]);
	
	const [queryParams, setQueryParams] = useState(() => {
		const storedParams = window.localStorage.getItem('productsParams');
		return storedParams ? JSON.parse(storedParams) : GET_PRODUCTS_DEFAULT_QUERY_PARAMS;
	});
	
	useEffect(() => {
		getProducts(queryParams);
	}, []);
	
	useEffect(() => {
		window.localStorage.setItem('productsParams', JSON.stringify(queryParams));
		getProducts(queryParams);
	}, [queryParams]);

	useEffect(() => {
		getCompaniesWithEndUsers();
		getProductModels();
		getMaintenancePlansOptions();
	}, []);

	const closeDeleteModal = useCallback(() => { setItemToRemove([]); }, []);

	return (
		<>
			<ProductsTable
				products={products}
				pagination={pagination}
				queryParams={queryParams}
				setQueryParams={setQueryParams}
				setItemToRemove={setItemToRemove}
				loading={loading}
			/>

			{itemToRemove.length > 0 && (
				<DeleteProductModal
					onHide={closeDeleteModal}
					products={itemToRemove}
					onDelete={() => { getProducts(queryParams); }}
				/>
			)}

		</>
	);
};

const mapStateToProps = (state) => {
	return {
		products: state.products.products,
		pagination: state.products.pagination,
		loading: state.products.loading,
		companiesWithEndUsers: state.companies.companiesWithEndUsers
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProducts: (queryParams) => { return dispatch(getProducts(queryParams)); },
		getCompaniesWithEndUsers: () => { return dispatch(getCompaniesWithEndUsers()); },
		getProductModels: () => { return dispatch(loadProductModels()); },
		getMaintenancePlansOptions: () => { return dispatch(getMaintenancePlansOptions()); },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
