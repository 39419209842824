import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getProductAddons } from '../../../../data/productAddons/actions';
import { getProductAddonDetails, removeProductAddonDetails } from '../../../../data/productAddons/actions';
import { ProductAddonsTable } from './table';
import NewModal from './Modals/NewModal';
import EditModal from './Modals/EditModal';
import { DeleteModal } from './Modals/DeleteModal';

const ProductAddons = (props) => {
	const { productAddons, getProductAddons, getProductAddonDetails, removeProductAddonDetails, loading, showAddProductAddonModal } = props;
	const [editedAddon, setEditedAddon] = useState(null);
	const [itemToRemove, setItemToRemove] = useState([]);
	
	useEffect(() => {
		getProductAddons();
	}, []);

	useEffect(() => {
		if (editedAddon && editedAddon.id) {
			getProductAddonDetails(editedAddon.id);
		} else {
			setEditedAddon(null);
		}
	}, [editedAddon]);

	const closeEditModal = () => { removeProductAddonDetails(); return setEditedAddon(null); };
	const closeDeleteModal = () => { return setItemToRemove([]); };

	return (
		<>
			<ProductAddonsTable
				addons={productAddons}
				loading={loading}
				setEditedAddon={setEditedAddon}
				setItemToRemove={setItemToRemove}
			/>
			{showAddProductAddonModal && (
				<NewModal
					afterUpdateCallback={() => { getProductAddons(); }}
				/>
			)}

			{editedAddon && (
				<EditModal
					editedAddon={editedAddon}
					afterUpdateCallback={() => { getProductAddons(); }}
					onHide={closeEditModal}
				/>
			)}

			{itemToRemove.length > 0 && (
				<DeleteModal
					onHide={closeDeleteModal}
					addons={itemToRemove}
					afterUpdateCallback={() => { getProductAddons(); }}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		productAddons: state.productAddons.data,
		loading: state.productAddons.loading,
		showAddProductAddonModal: state.productAddons.showAddProductAddonModal,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProductAddons: () => { return dispatch(getProductAddons()); },
		getProductAddonDetails: (id) => { return dispatch(getProductAddonDetails(id)); },
		removeProductAddonDetails: () => { return dispatch(removeProductAddonDetails()); },
		
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductAddons);
