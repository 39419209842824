const maintenanceDurations = {
	durationsList: [{
		label: '-------------------------',
		options: [
			{ value: 36, label: '3 years' },
			{ value: 60, label: '5 years' },
		]
	},
	{
		label: '-------------------------',
		options: [
			{ value: 12, label: '1 year' },
			{ value: 24, label: '2 years' },
			{ value: 48, label: '4 years' }
		]
	},
	{
		label: '-------------------------',
		options: [
			{ value: 1, label: '1 month' },
			{ value: 2, label: '2 months' },
			{ value: 3, label: '3 months' },
			{ value: 4, label: '4 months' },
			{ value: 5, label: '5 months' },
			{ value: 6, label: '6 months' },
			{ value: 7, label: '7 months' },
			{ value: 8, label: '8 months' },
			{ value: 9, label: '9 months' },
			{ value: 10, label: '10 months' },
			{ value: 11, label: '11 months' },
			{ value: 12, label: '12 months' }
		]
	}],
	minDurationEndUserPlan: 12,
	minDurationDemoPlan:    120
};

const maintenanceExpirationDefaultFilter = '6-months-fromNow';

const getDurationOption = (duration) => {
	if (!duration) {
		return null;
	}
	let optionIndex = 0;
	let list = maintenanceDurations.durationsList.find((durationGroup) => {
		optionIndex = durationGroup.options.findIndex((option) => {
			return option.value.toString() === duration.toString();
		});
		return optionIndex !== -1;
	});
	return list?.options[optionIndex] ?? null;
};

export {
	getDurationOption,
	maintenanceDurations,
	maintenanceExpirationDefaultFilter
}
;
