import { axios, API_DEFAULT_VERSION, backEndAxios, restAxios } from '../../../../../services/httpClient';
import * as actionTypes from './actionTypes';
import _ from 'lodash';

export const addSettingsForEmailFrequencies = (formValues) => {
	return (dispatch) => {
		return restAxios.patch('/system/settings', formValues)
			.then((response) => {
				if ( response.status === 200 ) {
					dispatch(addSettingsForEmailFrequenciesSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(addSettingsForEmailFrequenciesError(error?.response?.data?.detail[0]?.message));
			});
	};
};

export const addSettingsForEmailFrequenciesSuccess = (data) => {
	return {
		type:    actionTypes.ADD_SETTINGS_FOR_EMAIL_FREQUENCIES_SUCCESS,
		payload: data
	};
};

export const addSettingsForEmailFrequenciesError = (error) => {
	return {
		type:    actionTypes.ADD_SETTINGS_FOR_EMAIL_FREQUENCIES_ERROR,
		payload: error
	};
};

export const getRenewalsSettings = () => {
	return (dispatch) => {
		// return axios.get('/settings/renewals')
		return restAxios.get('/system/settings')
			.then((response) => {
				if ( response.status === 200 ) {
					dispatch(getRenewalsSettingsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(getRenewalsSettingsError(error?.response?.data?.message));
			});
	};
};

export const getRenewalsSettingsSuccess = (data) => {
	return {
		type:    actionTypes.GET_RENEWALS_SETTINGS_SUCCESS,
		payload: data
	};
};

export const getRenewalsSettingsError = (error) => {
	return {
		type:    actionTypes.GET_RENEWALS_SETTINGS_ERROR,
		payload: error
	};
};

export const editContactDetailsForSerenityRequests = (formValues) => {
	return (dispatch) => {
		return axios.put('/settings/serenity', formValues)
			.then((response) => {
				if ( response.status === 200 ) {
					dispatch(editContactDetailsForSerenityRequestsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(editContactDetailsForSerenityRequestsError(error?.response?.data?.message));
			});
	};
};

export const editContactDetailsForSerenityRequestsSuccess = (data) => {
	return {
		type:    actionTypes.EDIT_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_SUCCESS,
		payload: data
	};
};

export const editContactDetailsForSerenityRequestsError = (error) => {
	return {
		type:    actionTypes.EDIT_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_ERROR,
		payload: error
	};
};

export const getContactDetailsForSerenityRequests = () => {
	return (dispatch) => {
		return axios.get('/settings/serenity')
			.then((response) => {
				if ( response.status === 200 ) {
					dispatch(getContactDetailsForSerenityRequestsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(getContactDetailsForSerenityRequestsError(error));
			});
	};
};

export const getContactDetailsForSerenityRequestsSuccess = (data) => {
	return {
		type:    actionTypes.GET_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_SUCCESS,
		payload: data.serenitySettings
	};
};

export const getContactDetailsForSerenityRequestsError = (error) => {
	return {
		type:    actionTypes.GET_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_ERROR,
		payload: error
	};
};

export const updateRasSettings = (formValues) => {
	return (dispatch) => {
		return axios.put('/settings/ras', formValues)
			.then((response) => {
				if ( response.status === 200 ) {
					dispatch(updateRasSettingsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(updateRasSettingsError(error?.response?.data?.message));
			});
	};
};

export const updateRasSettingsSuccess = (data) => {
	return {
		type:    actionTypes.UPDATE_RAS_SETTINGS_SUCCESS,
		payload: data
	};
};

export const updateRasSettingsError = (error) => {
	return {
		type:    actionTypes.UPDATE_RAS_SETTINGS_ERROR,
		payload: error
	};
};

export const getRasSettings = () => {
	return (dispatch) => {
		return axios.get('/settings/ras')
			.then((response) => {
				if ( response.status === 200 ) {
					dispatch(getRasSettingsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(getRasSettingsError(error));
			});
	};
};

export const getRasSettingsSuccess = (data) => {
	return {
		type:    actionTypes.GET_RAS_SETTINGS_SUCCESS,
		payload: data
	};
};

export const getRasSettingsError = (error) => {
	return {
		type:    actionTypes.GET_RAS_SETTINGS_ERROR,
		payload: error
	};
};

export const getNpsSettings = () => {
	return (dispatch) => {
		return axios.get('/settings/nps')
			.then((response) => {
				if ( response.status === 200 ) {
					dispatch(getNpsSettingsSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(getNpsSettingsError(error?.response?.data?.message));
			});
	};
};

export const getNpsSettingsSuccess = (data) => {
	return {
		type:    actionTypes.GET_NPS_SETTINGS_SUCCESS,
		payload: data
	};
};

export const getNpsSettingsError = (error) => {
	return {
		type:    actionTypes.GET_NPS_SETTINGS_ERROR,
		payload: error
	};
};

export const editNpsSettings = (formFields) => {
	let npsScheduler = _.cloneDeep(formFields);
	npsScheduler.npsNotificationEnabled = npsScheduler.npsNotificationEnabled === true ? 'yes' : 'no';

	return (dispatch) => {
		return axios.put('/settings/nps', npsScheduler).then((response) => {
			dispatch(editNpsSettingsSuccess(response.data));
		}).catch((error) => {
			dispatch(editNpsSettingsError(error.response.data));
			throw new Error(error?.response?.data?.message);
		});
	};
};

export const editNpsSettingsSuccess = (data) => {
	return {
		type:    actionTypes.EDIT_NPS_SETTINGS_SUCCESS,
		payload: data
	};
};

export const editNpsSettingsError = (error) => {
	return {
		type:    actionTypes.EDIT_NPS_SETTINGS_ERROR,
		payload: error
	};
};

export const deleteMaintenanceServiceRenewal = (maintenanceServiceRenewalId) => {
	return (dispatch) => {
		return axios.delete(`/operations/maintenance-renewals/${ maintenanceServiceRenewalId }`)
			.then((response) => {
				if ( response.status === 200 ) {
					dispatch(deleteMaintenanceServiceRenewalSuccess(response.data));
				}
			}).catch((error) => {
				dispatch(deleteMaintenanceServiceRenewalError(error.response.data));
			});
	};
};

export const deleteMaintenanceServiceRenewalSuccess = (data) => {
	return {
		type:    actionTypes.DELETE_MAINTENANCE_SERVICE_RENEWAL_SUCCESS,
		payload: {
			status:  data.status,
			message: data.message
		}
	};
};

export const deleteMaintenanceServiceRenewalError = (error) => {
	return {
		type:    actionTypes.DELETE_MAINTENANCE_SERVICE_RENEWAL_ERROR,
		payload: {
			status:  error.status,
			message: error.message
		}
	};
};

export const getVirtualApplianceSettings = () => {
	return async (dispatch) => {
		try {
			const res = await backEndAxios.get(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/get-virtual-appliance-settings`);
			dispatch(getVirtualApplianceSettingsSuccess(res.data));
		} catch (error) {
			dispatch(getVirtualApplianceSettingsError(error));
		}
	};
};

export const getVirtualApplianceSettingsSuccess = (data) => {
	return {
		type:    actionTypes.GET_VIRTUAL_APPLIANCE_SETTINGS_SUCCESS,
		payload: data
	};
};

export const getVirtualApplianceSettingsError = (error) => {
	return {
		type:    actionTypes.GET_VIRTUAL_APPLIANCE_SETTINGS_ERROR,
		payload: error
	};
};

export const updateVirtualApplianceSettings = (formFields) => {
	return async (dispatch) => {
		try {
			const res = await backEndAxios.put(`/central-backend/${API_DEFAULT_VERSION}/internal-crud/update-virtual-appliance-settings`, formFields);
			dispatch(updateVirtualApplianceSettingsSuccess(res.data));
		} catch (error) {
			dispatch(updateVirtualApplianceSettingsError(error));
		}
	};
};

export const updateVirtualApplianceSettingsSuccess = (data) => {
	return {
		type:    actionTypes.UPDATE_VIRTUAL_APPLIANCE_SETTINGS_SUCCESS,
		payload: data
	};
};

export const updateVirtualApplianceSettingsError = (error) => {
	return {
		type:    actionTypes.UPDATE_VIRTUAL_APPLIANCE_SETTINGS_ERROR,
		payload: error
	};
};
