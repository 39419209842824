import * as actionTypes from '../actionTypes';

const INITIAL_STATE = {
	loading:                                    true,
	statusSetEmailFrequencies:                  null,
	statusSetContactDetailsForSerenityRequests: null,
	statusRasSettings:                          null,
	statusNpsSettings:                          null,
	statusVirtualApplianceSettings:             null,
	renewalSettings:                            {},
	rasSettings:                                {},
	npsSettings:                                {},
	virtualApplianceSettings:                   {},
	errorGetSettings:                           '',
	contactDetailsForSerenityRequests:          {},
	errorContactDetailsForSerenityRequests:     '',
	deleteMaintenanceRenewal:                   {
		status:  null,
		message: null
	}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case actionTypes.ADD_SETTINGS_FOR_EMAIL_FREQUENCIES_SUCCESS:
			return {
				...state,
				statusSetEmailFrequencies: action.payload.message
			};
		case actionTypes.ADD_SETTINGS_FOR_EMAIL_FREQUENCIES_ERROR:
			return {
				...state,
				statusSetEmailFrequencies: action.payload.message
			};
		case actionTypes.GET_RENEWALS_SETTINGS_SUCCESS:
			return {
				...state,
				loading:         false,
				renewalSettings: action.payload
			};
		case actionTypes.GET_RENEWALS_SETTINGS_ERROR:
			return {
				...state,
				loading:          false,
				errorGetSettings: action.payload.error
			};
		case actionTypes.EDIT_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_SUCCESS:
			return {
				...state,
				loading:                                    false,
				statusSetContactDetailsForSerenityRequests: action.payload.message
			};
		case actionTypes.EDIT_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_ERROR:
			return {
				...state,
				loading:                                    false,
				statusSetContactDetailsForSerenityRequests: action.payload
			};
		case actionTypes.GET_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_SUCCESS:
			return {
				...state,
				loading:                           false,
				contactDetailsForSerenityRequests: action.payload
			};
		case actionTypes.GET_CONTACT_DETAILS_FOR_SERENITY_REQUESTS_ERROR:
			return {
				...state,
				loading:                                false,
				errorContactDetailsForSerenityRequests: action.payload.error
			};
		case actionTypes.GET_RAS_SETTINGS_SUCCESS:
			return {
				...state,
				loading:     false,
				rasSettings: action.payload.rasSettings
			};
		case actionTypes.GET_RAS_SETTINGS_ERROR:
			return {
				...state,
				loading:     false,
				rasSettings: action.payload.error
			};
		case actionTypes.UPDATE_RAS_SETTINGS_SUCCESS:
			return {
				...state,
				loading:     false,
				statusRasSettings: action.payload.message
			};
		case actionTypes.UPDATE_RAS_SETTINGS_ERROR:
			return {
				...state,
				loading:     false,
				statusRasSettings: action.payload.error
			};
		case actionTypes.GET_NPS_SETTINGS_SUCCESS:
			return {
				...state,
				loading:     false,
				npsSettings: action.payload.npsSettings
			};
		case actionTypes.GET_NPS_SETTINGS_ERROR:
			return {
				...state,
				loading:     false,
				npsSettings: action.payload.error
			};
		case actionTypes.EDIT_NPS_SETTINGS_SUCCESS:
			return {
				...state,
				loading:     false,
				statusNpsSettings: action.payload.message
			};
		case actionTypes.EDIT_NPS_SETTINGS_ERROR:
			return {
				...state,
				loading:     false,
				statusNpsSettings: action.payload.error
			};
		case actionTypes.DELETE_MAINTENANCE_SERVICE_RENEWAL_SUCCESS:
			return {
				...state,
				loading:                  false,
				deleteMaintenanceRenewal: {
					status:  action.payload.status,
					message: action.payload.message
				}
			};
		case actionTypes.DELETE_MAINTENANCE_SERVICE_RENEWAL_ERROR:
			return {
				...state,
				loading:                  false,
				deleteMaintenanceRenewal: {
					status:  action.payload.status,
					message: action.payload.message
				}
			};
		case actionTypes.GET_VIRTUAL_APPLIANCE_SETTINGS_SUCCESS:
			return {
				...state,
				loading:                  false,
				virtualApplianceSettings: action.payload.virtualApplianceSettings
			};
		case actionTypes.GET_VIRTUAL_APPLIANCE_SETTINGS_ERROR:
			return {
				...state,
				loading:                  false,
				virtualApplianceSettings: action.payload.error
			};
		case actionTypes.UPDATE_VIRTUAL_APPLIANCE_SETTINGS_SUCCESS:
			return {
				...state,
				loading:                        false,
				statusVirtualApplianceSettings: action.payload.message
			};
		case actionTypes.UPDATE_VIRTUAL_APPLIANCE_SETTINGS_ERROR:
			return {
				...state,
				loading:                        false,
				statusVirtualApplianceSettings: action.payload.error
			};
		default: {
			return state;
		}
	}
};
