import RegexRules from '../../../../../../constants/regex-rules';

class ValidationService {

	static validate = (values) => {
		let errors = {};
		let hasErrorsValidateEmailFrequencySettings = ValidationService.validateEmailFrequenciesSettings(values, 'emailFrequenciesSettings');
		let hasErrorsAddonsExpirationEmailFrequencies = ValidationService.validateEmailFrequenciesSettings(values, 'addonsExpirationEmailFrequencies');
		let hasErrorsUnitsOfTimeEmailFrequencySettings = ValidationService.validateUnitsOfTimeEmailFrequenciesSettings(values, 'emailFrequenciesSettings');
		let hasErrorsUnitsOfTimeAddonsExpirationEmailFrequencies = ValidationService.validateUnitsOfTimeEmailFrequenciesSettings(values, 'addonsExpirationEmailFrequencies');
		let hasErrorsEmailAddressesInternalWeeklyReport = ValidationService.validateEmailAddressListWeeklyReport(values);

		if ( hasErrorsValidateEmailFrequencySettings ) {
			errors.emailFrequenciesSettings = 'An interval is required for sending emails.';
		}

		if ( hasErrorsAddonsExpirationEmailFrequencies ) {
			errors.addonsExpirationEmailFrequencies = 'An interval is required for sending emails.';
		}

		if ( hasErrorsUnitsOfTimeEmailFrequencySettings ) {
			errors.emailFrequenciesSettings = 'Units of time allowed: m, w, d';
		}

		if ( hasErrorsUnitsOfTimeAddonsExpirationEmailFrequencies ) {
			errors.addonsExpirationEmailFrequencies = 'Units of time allowed: m, w, d';
		}

		if ( hasErrorsEmailAddressesInternalWeeklyReport ) {
			errors.internalWeeklyReportSettings = 'One or more of the values provided are not valid email addresses';
		}

		return errors;
	};

	static validateEmailFrequenciesSettings(values, key) {
		let hasErrors = false;
		if ( !values[key] ) {
			hasErrors = true;
		}

		return hasErrors;
	}

	static validateUnitsOfTimeEmailFrequenciesSettings(values, key) {
		let hasErrors = false;
		let allowedUnitsOfTime = ['m', 'w', 'd'];
		let allowedValuesOfTime = /^\d+$/;
		let frequenciesArr = [];

		if ( values[key] ) {
			frequenciesArr = values[key].split(',');
			let unitsOfTime = frequenciesArr.map((frequency) => {
				return frequency.substring(frequency.length - 1);
			});
			let digits = frequenciesArr.map((frequency) => {
				return frequency.substring(0, frequency.length - 1).trim();
			});
			let found = unitsOfTime.every((element) => { return allowedUnitsOfTime.includes(element); });
			let isNumber = digits.every((element) => { return allowedValuesOfTime.test(element); });
			if ( !found || !isNumber ) {
				hasErrors = true;
			}
		}

		return hasErrors;
	}

	static validateEmailAddressListWeeklyReport(values) {
		let hasErrors = false;
		let emailAddresses = [];
		const emailAddressRegex = RegexRules.validEmailRegex;
		if ( values.internalWeeklyReportSettings ) {
			emailAddresses = values.internalWeeklyReportSettings.split(',');
			let validEmailAddresses = emailAddresses.every((email) => { return emailAddressRegex.test(email.trim()); });
			if ( !validEmailAddresses ) {
				hasErrors = true;
			}
		}

		return hasErrors;
	}
}

export default ValidationService;

