import React, { useState } from 'react';
import { Tabs } from '@syneto/compass-react';
import Form from '../../../../components/forms/form';

const AdminVirtualApplianceDistributionLinks = (props) => {
	const [activeKey, setActiveKey] = useState('os4');

	return (
		<div id="distribution-links " className="col-12">
			<h1 className="mb-1">Distribution Links</h1>
			<p className="text-secondary mb-4 text-value">
				Configure the locations where the Virtual Appliance software and documentation are available for download.
			</p>

			<Tabs activeKey={activeKey} onSelect={(tab) => { return setActiveKey(tab); }}>
				<Tabs.Tab eventKey="os4" title="Syneto OS4" className="os4-distribution-links">
					<Form.text name="virtualApplianceOs4GuideUrl" label="Installation Guide URL:" />
					<Form.text name="virtualApplianceOs4IsoUrl" label="Installation URL:" />
					<Form.text name="virtualApplianceOs4MD5Url" label="MD5 File URL:" />


				</Tabs.Tab>
				<Tabs.Tab eventKey="os5" title="Syneto OS5" className="os5-distribution-links">
					<Form.text name="virtualApplianceOs5GuideUrl" label="Installation Guide URL:" />
					<Form.text name="virtualApplianceOs5IsoUrl" label="Installation URL:" />
					<Form.text name="virtualApplianceOs5MD5Url" label="MD5 File URL:" />
					<Form.text name="firewallRequirementsUrl" label="See firewall requirements:" />
				</Tabs.Tab>
			</Tabs>
		</div>
	);
};

export default AdminVirtualApplianceDistributionLinks;
